<app-logged class="liquidation-transfer-settings-form">
  <header class="liquidation-transfer-settings-form__header">
    <h2>CONFIGURAÇÕES DE TEDs</h2>
    <div class="liquidation-transfer-settings-form__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/home">VOLTAR</button>
    </div>
  </header>
  
  <form [formGroup]="settingsForm" (ngSubmit)="submitForm()">
    <mat-card>
      <div class="row">
        <div class="col-6 col-sm-6">
          <mat-card-title>Configurações</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>CNPJ do parceiro JHSFID para recebimento da taxa administrativa</mat-label>
              <input matInput formControlName="administrativeTaxCnpjReceiver" mask="00.000.000/0000-00" />
              <mat-error *ngIf="settingsForm.controls.administrativeTaxCnpjReceiver?.invalid">
                {{ errorsService.messageErrorFor(settingsForm.controls.administrativeTaxCnpjReceiver) }}
              </mat-error>
            </mat-form-field>       
            
            <mat-form-field>
              <mat-label>Ao varrer as transações para agendamentos de TED, defina de quantos dias atrás devem ser pegas as transações </mat-label>
              <input matInput formControlName="scheduleAdvanceInDays" type="number" />
              <mat-error *ngIf="settingsForm.controls.scheduleAdvanceInDays?.invalid">
                {{ errorsService.messageErrorFor(settingsForm.controls.scheduleAdvanceInDays) }}
              </mat-error>
            </mat-form-field>  

            <div formArrayName="emailsForBalanceAlert">
              <mat-label>Emails para receberem alerta de saldo insuficiente na conta para a realização das TEDs </mat-label>
              <br>
              <div>
                <button mat-button type="button" (click)="addEmail()">+ Adicionar Email</button>
              </div>
              <br>
              <ng-container *ngFor="let email of emailControls.controls; let i = index">
              
                <mat-form-field class="col-6 col-sm-6">
                  <mat-label>Digite o email</mat-label>
                  <input matInput [formControlName]="i" />
                  <button mat-icon-button matSuffix  (click)="removeEmail(i)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </mat-form-field>
                
              </ng-container>           
            
            </div>            

            <h3>Informações Bancárias - Integração Banco Arbi</h3>
            <mat-form-field>
              <mat-label>Banco origem</mat-label>
              <input matInput formControlName="bancoOrigem" />
              <mat-error *ngIf="settingsForm.controls.bancoOrigem?.invalid">
                {{ errorsService.messageErrorFor(settingsForm.controls.bancoOrigem) }}
              </mat-error>
            </mat-form-field> 
          
            <mat-form-field>
              <mat-label>Agência origem</mat-label>
              <input matInput formControlName="agenciaOrigem" />
              <mat-error *ngIf="settingsForm.controls.agenciaOrigem?.invalid">
                {{ errorsService.messageErrorFor(settingsForm.controls.agenciaOrigem) }}
              </mat-error>
            </mat-form-field> 

            <mat-form-field>
              <mat-label>Conta origem</mat-label>
              <input matInput formControlName="contaOrigem" />
              <mat-error *ngIf="settingsForm.controls.contaOrigem?.invalid">
                {{ errorsService.messageErrorFor(settingsForm.controls.contaOrigem) }}
              </mat-error>
            </mat-form-field> 
          
            <mat-form-field>
              <mat-label>Tipo Conta Debitada</mat-label>
              <input matInput formControlName="tipoContaDebitada" />
              <mat-error *ngIf="settingsForm.controls.tipoContaDebitada?.invalid">
                {{ errorsService.messageErrorFor(settingsForm.controls.tipoContaDebitada) }}
              </mat-error>
            </mat-form-field> 

            <mat-form-field>
              <mat-label>Token Usuário</mat-label>
              <input matInput formControlName="tokenUsuario" />
              <mat-error *ngIf="settingsForm.controls.tokenUsuario?.invalid">
                {{ errorsService.messageErrorFor(settingsForm.controls.tokenUsuario) }}
              </mat-error>
            </mat-form-field> 

            <mat-form-field>
              <mat-label>Inscrição parceiro</mat-label>
              <input matInput formControlName="inscricaoParceiro" />
              <mat-error *ngIf="settingsForm.controls.inscricaoParceiro?.invalid">
                {{ errorsService.messageErrorFor(settingsForm.controls.inscricaoParceiro) }}
              </mat-error>
            </mat-form-field> 

          </mat-card-content>          
          
        </div>
      </div>

      <div class="liquidation-transfer-settings-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
