import { Component, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { CurrencyPipe } from '@angular/common'
import { MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material'
import JsFileDownloader from 'js-file-downloader'
import * as moment from 'moment'
import { ErrorsService } from '../../../../core/services/errors.service'
import { ReportService } from '../../../../services/report.service'

@Component({
  selector: 'app-store-form',
  templateUrl: './reports-consolidated-jcoins.component.html',
  styleUrls: ['./reports-consolidated-jcoins.component.scss'],
})
export class ConsolidatedJcoinsComponent implements OnInit {
  public formGroup: FormGroup
  public headersTable: string[]
  public dataSourceLastMonth: any
  public typesAccumulation: any
  public fieldsForm: any
  public returnFilterMonthAndYear
  public date: any

  public pageTotal: number
  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

  constructor (
    public errorsService: ErrorsService,
    public reportService: ReportService,
    private readonly snackBar: MatSnackBar,
    public currencyPipe: CurrencyPipe,
    public dialog: MatDialog,
  ) { }

  ngOnInit () {
    this.headersTable = ['name', 'total']

    this.fieldsForm = [
      {
        field: 'input',
        type: 'text',
        formControlName: 'reportConsolidatedMonth',
        label: 'Mês',
        placeholder: 'Mês',
      },
      {
        field: 'input',
        type: 'text',
        formControlName: 'reportConsolidatedYear',
        label: 'Ano',
        placeholder: 'Ano',
      },
    ]

    this.reportService.consolidatedJcoins().then(
      response => {
        this.dataSourceLastMonth = new MatTableDataSource(response.typesLastMonth)
        const dateTotalLastMonth = response.typesLastMonth.find((t) => t.dateTotalLastMonth)
        this.date = dateTotalLastMonth ? dateTotalLastMonth.dateTotalLastMonth : null

        this.typesAccumulation = new MatTableDataSource(response.typesAccumulation)
      },
      error => {
        console.log(error)
        this.snackBar.open(error.error.message)
      },
    )
  }

  convertName (name: string) {
    if (name === 'sumTotalLastMonth') {
      return 'SALDO INICIAL'
    }
    if (name === 'totalBalancePreRegistration') {
      return 'Total Pré-Cadastros'
    }
    if (name === 'totalBalanceCustomer') {
      return 'Total Clientes'
    }
    if (name === 'totalBalanceAirportsCompanies') {
      return 'Total Empresas Aeroporto'
    }
    if (name === 'totalAmount') {
      return 'TOTAL'
    }
    if (name === 'typeAccumulationApp') {
      return 'ACUMULO APP'
    }
    if (name === 'movements') {
      return 'MOVIMENTAÇÕES'
    }
    if (name === 'totalAccumulationApp' || name === 'totalAccumulationCustomers') {
      return 'Clientes'
    }
    if (name === 'totalPreRegistration') {
      return 'Pré-cadastro'
    }
    if (name === 'totalPreRegistrationAccumulationAirportsCompany') {
      return 'Empresas Aeroporto'
    }
    if (name === 'totalAccumulationAirportsCompany') {
      return 'Acumulo Aeroporto'
    }
    if (name === 'totalAccumulationIncorporations') {
      return 'Acumulo Incorporação'
    }
    if (name === 'typeAccumulationManual') {
      return 'ACUMULO MANUAL'
    }
    if (name === 'totalMovements') {
      return 'TOTAL MOVIMENTAÇÕES'
    }
    if (name === 'totalRescue') {
      return 'RESGATE DE JCOINS'
    }
    if (name === 'totalFinal') {
      return 'SALDO FINAL'
    }
  }

  export (name: string) {
    if (!this.returnFilterMonthAndYear) {
      const month = moment().format('MM')
      const year = moment().format('YYYY')
      this.returnFilterMonthAndYear = `?month=${month}&year=${year}`
    }
    if (name === 'customers') {
      this.reportService
        .createReport(`customer/month-balance${this.returnFilterMonthAndYear}`)
        .then(async response => {
          // eslint-disable-next-line no-new
          new JsFileDownloader({
            url: response.fileUrl,
            nameCallback: () => {
              // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
              return 'Relatório de Estoque de J-Coins (Mês).' + response.fileUrl.split('.').splice(5, 5).join('.')
            },
          })
        })
        .catch(error => {
          this.snackBar.open(error.message)
        })
    }

    if (name === 'preRegistrations') {
      this.reportService
        .createReport(`customer/pre-registrations-month-balance${this.returnFilterMonthAndYear}`)
        .then(async response => {
          // eslint-disable-next-line no-new
          new JsFileDownloader({
            url: response.fileUrl,
            nameCallback: () => {
              // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
              return 'Relatório de Estoque de J-Coins (Mês).' + response.fileUrl.split('.').splice(5, 5).join('.')
            },
          })
        })
        .catch(error => {
          this.snackBar.open(error.message)
        })
    }

    if (name === 'preRegistrationAirport') {
      this.reportService
        .createReport(`customer/pre-registration-airports-month-balance${this.returnFilterMonthAndYear}`)
        .then(async response => {
          // eslint-disable-next-line no-new
          new JsFileDownloader({
            url: response.fileUrl,
            nameCallback: () => {
              // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
              return 'Relatório de Estoque de J-Coins (Mês).' + response.fileUrl.split('.').splice(5, 5).join('.')
            },
          })
        })
        .catch(error => {
          this.snackBar.open(error.message)
        })
    }
  }

  reciverFeedback (returnFilter) {
    this.dataSourceLastMonth = new MatTableDataSource(returnFilter.typesLastMonth)
    this.typesAccumulation = new MatTableDataSource(returnFilter.typesAccumulation)
    const dateTotalLastMonth = returnFilter.typesLastMonth.find((t) => t.dateTotalLastMonth)

    this.date = dateTotalLastMonth ? dateTotalLastMonth.dateTotalLastMonth : null
    this.pageTotal = returnFilter.total
    this.returnFilterMonthAndYear = returnFilter.filter
  }
}
