<app-logged class="store-list">
  <header class="store-list__header" *ngIf="schedule" >
    <h2>
      AGENDAMENTO DE TRANSFERENCIA DE LIQUIDAÇÃO - Parceiro: {{ schedule.transfer[0].storeName }}
    </h2>
    <div class="store-list__header__actions">
      <button
        class="btn-primary-light"
        mat-button
        (click)="goBack()"
      >
        VOLTAR
      </button>
    </div>
  </header>

  <div *ngIf="schedule">
    <div>
      <strong>Status:</strong>
      {{ schedule.status === true ? 'Concluído' : schedule.status === false ? 'Erro' : 'Pendente' }}
    </div>

    <div>
      <strong> CNPJ:</strong>
      {{ schedule.transfer[0].storeCnpj | mask: '00.000.000/0000-00' }}
    </div>

    <div>
      <strong> Agendado para:</strong>
      {{ schedule.dueDate | date: 'dd/MM/yyyy' }}
    </div>
    <div>
      <strong>Criado em:</strong>
      {{ schedule.createdAt | date: 'dd/MM/yyyy' }}
    </div>

    <div>
      <strong>Pago em:</strong>
      {{ schedule.paidAt | date: 'dd/MM/yyyy' }}
    </div>

    <div>
      <strong>Tipo:</strong>
      {{
        schedule.reason == 'liquidation'
          ? 'Liquidação de parceiros'
          : 'Transferencias de taxa administrativa'
      }}
    </div>
  </div>

  <div class="row" *ngIf="transfers">
    <div class="col-sm-12">
      <div class="table-responsive">
        <table mat-table [dataSource]="transfers">
          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef>Criado em</th>
            <td mat-cell *matCellDef="let element">
              {{ element.createdAt | date: 'dd/MM/yyyy HH:mm' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="totalValue">
            <th mat-header-cell *matHeaderCellDef>Valor</th>
            <td mat-cell *matCellDef="let element">
              {{ element.totalValue | currency: 'R$' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element">
              {{
                element.success === true
                  ? 'Concluído'
                  : element.success === false
                  ? 'Erro'
                  : 'Pendente'
              }}
            </td>
          </ng-container>
          <ng-container matColumnDef="executedAt">
            <th mat-header-cell *matHeaderCellDef>Executado em</th>
            <td mat-cell *matCellDef="let element">
              {{ element.executedAt | date: 'dd/MM/yyyy' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Ações</th>
            <td mat-cell *matCellDef="let element">
              <button
                mat-icon-button
                matTooltip="Detalhes"
                matTooltipPosition="left"
                (click)="openTransferDetails(element)"
              >
                <mat-icon>remove_red_eye</mat-icon>
              </button>

              <button
                mat-icon-button
                matTooltip="Verificar logs"
                matTooltipPosition="left"
                (click)="openTransferLogs(element)"
              >
                <mat-icon>history</mat-icon>
              </button>

              <button
                *ngIf="element.success === false && schedule.success !== true"
                mat-icon-button
                matTooltip="Fazer transferência manualmente"
                matTooltipPosition="left"
                [disabled]="retryButtonsClicked[element.id] ? 'disabled' : null"
                (click)="retryFailedTransfer(element)"
              >
                <mat-icon>attach_money</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
          <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
        </table>
      </div>
    </div>
  </div>
</app-logged>
