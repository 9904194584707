//create a component
import { Component, OnInit, ViewChild } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { MatPaginator, MatTableDataSource } from '@angular/material'
import { Params } from '@angular/router'
import { LiquidationTransferService } from 'src/app/services/liquidationTransfer.service'

@Component({
  selector: 'app-store-liquidation-transfers',
  templateUrl: './store-liquidation-transfers.component.html',
  styleUrls: ['./store-liquidation-transfers.component.scss']
})
export class StoreLiquidationTransfersComponent implements OnInit {
  public schedules: any

  public headersTable: string[] = ['dueDate', 'status', 'totalValue', 'paidAt', 'actions']

  fieldsForm: any
  public returnedFilters: any
  public pageTotal: number
  dataSource: any
  resultsAll: any

  public formGroup: FormGroup
  public params: Params

  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

  storeSelected: any

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator

  constructor (
    private readonly liquidationTransferService: LiquidationTransferService,
  ) {
    this.storeSelected = JSON.parse(localStorage.getItem('storeSelected'))
  }

  ngOnInit (): void {
    this.renderScheduleList()

    this.fieldsForm = [     
      {
        field: 'input',
        type: 'date',
        label: 'Agendado para - Data Início',
        formControlName: 'startDueDateTransferScheduleList',
      },
      {
        formControlName: 'endDueDateTransferScheduleList',
        field: 'input',
        type: 'date',
        label: 'Agendado para - Data Final',
      },
      {
        field: 'input',
        type: 'date',
        label: 'Pago em - Data Início',
        formControlName: 'startPaidAtDateTransferScheduleList',
      },
      {
        formControlName: 'endPaidAtDateTransferScheduleList',
        field: 'input',
        type: 'date',
        label: 'Pago em - Data Final',
      },     
    ]
  }

  async renderScheduleList () {
    const { data, pagination } = await this.liquidationTransferService.listShedulesfromStore(this.storeSelected.storeId, `?&page=${this.page}&limit=${this.pageSize}`).toPromise()
    this.schedules = new MatTableDataSource(data)
    this.pageTotal = pagination.TotalRegisters
  }

  totalValue (transfer: any) {
    return transfer.length ?
      transfer.find(() => true).totalValue
      : 0
  }

  reciverFeedback (returnFilter) {
    // this.returnedFilters = returnFilter.data
    this.pageTotal = returnFilter.pagination.TotalRegisters
    this.page = returnFilter.pagination.CurrentPage
    this.schedules = new MatTableDataSource(returnFilter.data)
    this.resultsAll = returnFilter.data
    this.formGroup = returnFilter.formGroup.value
    this.params = {
      formGroupValue: JSON.stringify(this.formGroup),
    }
  }

}
