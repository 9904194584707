import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { User } from '../models/user'
import { enumCmsPermissions } from '../models/enumCmsPermissions'
// import { BusinessPartner } from '../models/businessPartner'

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  private userInfo: User
  public userPermissions: any[];

  constructor() {
    
  }

  loadUserInfo() {
    try {
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'))              
      this.userPermissions = this.userInfo.permissions
    } catch(err) {
      console.log("Failed to get user permissions", err)
    }
  }

  userHasPermission(permission: enumCmsPermissions) {    
    this.loadUserInfo()

    const userPermissions = this.userPermissions || []

    const permissionKey =  Object.keys(enumCmsPermissions)[Object.values(enumCmsPermissions).indexOf(permission)]    
    return userPermissions.includes(permissionKey)
  }
}