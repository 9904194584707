<app-logged class="store-list">
  <header class="store-list__header">
    <h2 *ngIf="typePreRegistration === 'CMS'">Pré-Cadastro Clientes incorporação/aeroporto</h2>
    <h2 *ngIf="!typePreRegistration">Pré-Cadastro Outros clientes</h2>
    <h2 *ngIf="typePreRegistration === 'architects'">Pré-Cadastro Arquitetos</h2>
    <div class="store-list__header__actions">
      <button
        *ngIf="
          !typePreRegistration ||
          typePreRegistration === 'CMS' ||
          typePreRegistration === 'architects'
        "
        class="btn-primary-light"
        mat-button
        (click)="report()"
      >
        EXPORTAR
      </button>
      <button
        *ngIf="!typePreRegistration"
        class="btn-primary-light"
        mat-button
        routerLink="/dashboard/pre-registration/others/new"
      >
        ADICIONAR
      </button>
      <button
        *ngIf="typePreRegistration === 'CMS'"
        class="btn-primary-light"
        mat-button
        routerLink="/dashboard/pre-registration/CMS/new"
      >
        ADICIONAR
      </button>
      <button
        *ngIf="typePreRegistration === 'architects'"
        class="btn-primary-light"
        mat-button
        routerLink="/dashboard/pre-registration-architect/architects/new"
      >
        ADICIONAR
      </button>
    </div>
  </header>

  <app-form-filter
    maskExample="000.000.000-00"
    actions="form"
    type="preRegistration"
    [fieldsForm]="fieldsForm"
    [formGroupValue]="formGroup"
    [typePreRegistration]="typePreRegistration"
    (returnFilter)="reciverFeedback($event)"
  ></app-form-filter>

  <div class="table-responsive">
    <table mat-table [dataSource]="preRegistration">
      <ng-container matColumnDef="cpf">
        <th mat-header-cell *matHeaderCellDef>CPF</th>
        <td mat-cell *matCellDef="let element">{{ element.cpf | mask: '000.000.000-00' }}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Nome</th>
        <td mat-cell *matCellDef="let element">{{ element.firstName }} {{ element.lastName }}</td>
      </ng-container>

      <ng-container matColumnDef="isActive">
        <th mat-header-cell *matHeaderCellDef>Status Membership</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="!element.isMembership" matTooltip="Membership Inativo">close</mat-icon>
          <mat-icon
            [ngStyle]="{
              color: 'green'
            }"
            *ngIf="element.isMembership"
            matTooltip="Membership Ativo"
            >check_circle</mat-icon
          >
        </td>
      </ng-container>

      <ng-container matColumnDef="Jcoins" *ngIf="typePreRegistration === 'architects'">
        <th mat-header-cell *matHeaderCellDef>J-Coins</th>
        <td mat-cell *matCellDef="let element" matTooltip="Saldo Atual / Saldo Futuro">
          {{ element.balance || 0 }} / {{ element.balancePending || 0 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Jcoins" *ngIf="typePreRegistration !== 'architects'">
        <th mat-header-cell *matHeaderCellDef>J-Coins</th>
        <td mat-cell *matCellDef="let element">
          {{ element.balance || 0 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let element">
          <!-- <button
            *ngIf="typePreRegistration"
            mat-icon-button
            matTooltip="Histórico de JCoins"
            matTooltipPosition="left"
            routerLink="/dashboard/pre-registration/historic-stores/{{ element.cpf }}"
            [queryParams]="params"
            skipLocationChange="true"
          >
            <mat-icon>swap_horiz</mat-icon>
          </button>
          <button
            *ngIf="!typePreRegistration"
            mat-icon-button
            matTooltip="Histórico de JCoins"
            matTooltipPosition="left"
            routerLink="/dashboard/pre-registration/historic-stores/{{ element.cpf }}"
            [queryParams]="params"
            skipLocationChange="true"
          >
            <mat-icon>swap_horiz</mat-icon>
          </button> -->
          <button
            *ngIf="!typePreRegistration || typePreRegistration === 'CMS'"
            mat-icon-button
            matTooltip="Histórico de JCoins"
            matTooltipPosition="left"
            routerLink="/dashboard/pre-registration/historic-stores/{{ element.cpf }}"
            [queryParams]="params"
            skipLocationChange="true"
          >
            <mat-icon>swap_horiz</mat-icon>
          </button>

          <button
            *ngIf="!typePreRegistration"
            mat-icon-button
            matTooltip="Endereço"
            matTooltipPosition="left"
            routerLink="/dashboard/pre-registration/{{ element.id }}/address"
            [queryParams]="params"
            skipLocationChange="true"
          >
            <mat-icon>home</mat-icon>
          </button>
          <button
            *ngIf="typePreRegistration"
            mat-icon-button
            matTooltip="Endereço"
            matTooltipPosition="left"
            routerLink="/dashboard/pre-registration/{{ element.id }}/address/{{
              typePreRegistration
            }}"
            [queryParams]="params"
            skipLocationChange="true"
          >
            <mat-icon>home</mat-icon>
          </button>
          <button
            *ngIf="typePreRegistration === 'CMS'"
            mat-icon-button
            matTooltip="Termos"
            matTooltipPosition="left"
            routerLink="/dashboard/customer/{{ element.cpf }}/terms/pre-register-cms"
            [queryParams]="params"
            skipLocationChange="true"
          >
            <mat-icon>description</mat-icon>
          </button>
          <button
            *ngIf="!typePreRegistration"
            mat-icon-button
            matTooltip="Termos"
            matTooltipPosition="left"
            routerLink="/dashboard/customer/{{ element.cpf }}/terms/pre-register"
            [queryParams]="params"
            skipLocationChange="true"
          >
            <mat-icon>description</mat-icon>
          </button>
          <button
            *ngIf="!typePreRegistration"
            mat-icon-button
            matTooltip="Editar"
            matTooltipPosition="left"
            routerLink="/dashboard/pre-registration/{{ element.id }}/edit"
            [queryParams]="params"
            skipLocationChange="true"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            *ngIf="typePreRegistration"
            mat-icon-button
            matTooltip="Editar"
            matTooltipPosition="left"
            routerLink="/dashboard/pre-registration/{{ element.id }}/edit/{{ typePreRegistration }}"
            [queryParams]="params"
            skipLocationChange="true"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            *ngIf="userRole !== 'CUSTOMER_SERVICE'"
            mat-icon-button
            matTooltip="Remover"
            matTooltipPosition="left"
            (click)="remove(element.id)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
    <app-form-filter
      type="preRegistration"
      actions="pagination"
      [length]="pageTotal"
      [pageSizeOptions]="pageSizeOptions"
      [formGroupValue]="formGroup"
      [typePreRegistration]="typePreRegistration"
      (returnFilter)="reciverFeedback($event)"
    >
    </app-form-filter>
  </div>
</app-logged>
