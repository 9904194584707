import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import JsFileDownloader from 'js-file-downloader';
import * as moment from 'moment';
var ConsolidatedJcoinsComponent = /** @class */ (function () {
    function ConsolidatedJcoinsComponent(errorsService, reportService, snackBar, currencyPipe, dialog) {
        this.errorsService = errorsService;
        this.reportService = reportService;
        this.snackBar = snackBar;
        this.currencyPipe = currencyPipe;
        this.dialog = dialog;
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    }
    ConsolidatedJcoinsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.headersTable = ['name', 'total'];
        this.fieldsForm = [
            {
                field: 'input',
                type: 'text',
                formControlName: 'reportConsolidatedMonth',
                label: 'Mês',
                placeholder: 'Mês',
            },
            {
                field: 'input',
                type: 'text',
                formControlName: 'reportConsolidatedYear',
                label: 'Ano',
                placeholder: 'Ano',
            },
        ];
        this.reportService.consolidatedJcoins().then(function (response) {
            _this.dataSourceLastMonth = new MatTableDataSource(response.typesLastMonth);
            var dateTotalLastMonth = response.typesLastMonth.find(function (t) { return t.dateTotalLastMonth; });
            _this.date = dateTotalLastMonth ? dateTotalLastMonth.dateTotalLastMonth : null;
            _this.typesAccumulation = new MatTableDataSource(response.typesAccumulation);
        }, function (error) {
            console.log(error);
            _this.snackBar.open(error.error.message);
        });
    };
    ConsolidatedJcoinsComponent.prototype.convertName = function (name) {
        if (name === 'sumTotalLastMonth') {
            return 'SALDO INICIAL';
        }
        if (name === 'totalBalancePreRegistration') {
            return 'Total Pré-Cadastros';
        }
        if (name === 'totalBalanceCustomer') {
            return 'Total Clientes';
        }
        if (name === 'totalBalanceAirportsCompanies') {
            return 'Total Empresas Aeroporto';
        }
        if (name === 'totalAmount') {
            return 'TOTAL';
        }
        if (name === 'typeAccumulationApp') {
            return 'ACUMULO APP';
        }
        if (name === 'movements') {
            return 'MOVIMENTAÇÕES';
        }
        if (name === 'totalAccumulationApp' || name === 'totalAccumulationCustomers') {
            return 'Clientes';
        }
        if (name === 'totalPreRegistration') {
            return 'Pré-cadastro';
        }
        if (name === 'totalPreRegistrationAccumulationAirportsCompany') {
            return 'Empresas Aeroporto';
        }
        if (name === 'totalAccumulationAirportsCompany') {
            return 'Acumulo Aeroporto';
        }
        if (name === 'totalAccumulationIncorporations') {
            return 'Acumulo Incorporação';
        }
        if (name === 'typeAccumulationManual') {
            return 'ACUMULO MANUAL';
        }
        if (name === 'totalMovements') {
            return 'TOTAL MOVIMENTAÇÕES';
        }
        if (name === 'totalRescue') {
            return 'RESGATE DE JCOINS';
        }
        if (name === 'totalFinal') {
            return 'SALDO FINAL';
        }
    };
    ConsolidatedJcoinsComponent.prototype.export = function (name) {
        var _this = this;
        if (!this.returnFilterMonthAndYear) {
            var month = moment().format('MM');
            var year = moment().format('YYYY');
            this.returnFilterMonthAndYear = "?month=" + month + "&year=" + year;
        }
        if (name === 'customers') {
            this.reportService
                .createReport("customer/month-balance" + this.returnFilterMonthAndYear)
                .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    // eslint-disable-next-line no-new
                    new JsFileDownloader({
                        url: response.fileUrl,
                        nameCallback: function () {
                            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                            return 'Relatório de Estoque de J-Coins (Mês).' + response.fileUrl.split('.').splice(5, 5).join('.');
                        },
                    });
                    return [2 /*return*/];
                });
            }); })
                .catch(function (error) {
                _this.snackBar.open(error.message);
            });
        }
        if (name === 'preRegistrations') {
            this.reportService
                .createReport("customer/pre-registrations-month-balance" + this.returnFilterMonthAndYear)
                .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    // eslint-disable-next-line no-new
                    new JsFileDownloader({
                        url: response.fileUrl,
                        nameCallback: function () {
                            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                            return 'Relatório de Estoque de J-Coins (Mês).' + response.fileUrl.split('.').splice(5, 5).join('.');
                        },
                    });
                    return [2 /*return*/];
                });
            }); })
                .catch(function (error) {
                _this.snackBar.open(error.message);
            });
        }
        if (name === 'preRegistrationAirport') {
            this.reportService
                .createReport("customer/pre-registration-airports-month-balance" + this.returnFilterMonthAndYear)
                .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    // eslint-disable-next-line no-new
                    new JsFileDownloader({
                        url: response.fileUrl,
                        nameCallback: function () {
                            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                            return 'Relatório de Estoque de J-Coins (Mês).' + response.fileUrl.split('.').splice(5, 5).join('.');
                        },
                    });
                    return [2 /*return*/];
                });
            }); })
                .catch(function (error) {
                _this.snackBar.open(error.message);
            });
        }
    };
    ConsolidatedJcoinsComponent.prototype.reciverFeedback = function (returnFilter) {
        this.dataSourceLastMonth = new MatTableDataSource(returnFilter.typesLastMonth);
        this.typesAccumulation = new MatTableDataSource(returnFilter.typesAccumulation);
        var dateTotalLastMonth = returnFilter.typesLastMonth.find(function (t) { return t.dateTotalLastMonth; });
        this.date = dateTotalLastMonth ? dateTotalLastMonth.dateTotalLastMonth : null;
        this.pageTotal = returnFilter.total;
        this.returnFilterMonthAndYear = returnFilter.filter;
    };
    return ConsolidatedJcoinsComponent;
}());
export { ConsolidatedJcoinsComponent };
