import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import JsFileDownloader from 'js-file-downloader';
var LiquidationTransferSchedulesList = /** @class */ (function () {
    function LiquidationTransferSchedulesList(liquidationTransferService) {
        this.liquidationTransferService = liquidationTransferService;
        this.headersTable = ['receiver', 'cnpj', 'dueDate', 'status', 'reason', 'createdAt', 'totalValue', 'actions'];
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    }
    LiquidationTransferSchedulesList.prototype.ngOnInit = function () {
        this.renderScheduleList();
        this.fieldsForm = [
            {
                field: 'input',
                type: 'text',
                formControlName: 'cnpjTransferScheduleList',
                label: 'CNPJ',
                placeholder: 'CNPJ',
            },
            {
                field: 'input',
                type: 'date',
                label: 'Agendado para - Data Início',
                formControlName: 'startDueDateTransferScheduleList',
            },
            {
                formControlName: 'endDueDateTransferScheduleList',
                field: 'input',
                type: 'date',
                label: 'Agendado para - Data Final',
            },
            {
                field: 'input',
                type: 'date',
                label: 'Pagamento - Data Início',
                formControlName: 'startPaidAtDateTransferScheduleList',
            },
            {
                formControlName: 'endPaidAtDateTransferScheduleList',
                field: 'input',
                type: 'date',
                label: 'Pagamento - Data Final',
            },
            {
                field: 'select',
                formControlName: 'reasonTransferScheduleList',
                label: 'Tipo Transferência',
                placeholder: 'Tipo Transferência',
                list: [
                    { key: 'administrative_tax', value: '	Transferencias de taxa administrativa' },
                    { key: 'liquidation', value: '	Liquidação de parceiros' },
                ],
                optionDefault: 'TODOS',
            },
        ];
        this.loadAccountBalanceAlert();
    };
    LiquidationTransferSchedulesList.prototype.loadAccountBalanceAlert = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.getAccountBalanceAlert()];
                    case 1:
                        _a.balanceAlert = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    LiquidationTransferSchedulesList.prototype.renderScheduleList = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, data, pagination;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.liquidationTransferService.listShedules("?&page=" + this.page + "&limit=" + this.pageSize).toPromise()];
                    case 1:
                        _a = _b.sent(), data = _a.data, pagination = _a.pagination;
                        this.schedules = new MatTableDataSource(data);
                        this.pageTotal = pagination.TotalRegisters;
                        return [2 /*return*/];
                }
            });
        });
    };
    LiquidationTransferSchedulesList.prototype.totalValue = function (transfer) {
        return transfer.length ?
            transfer.find(function () { return true; }).totalValue
            : 0;
    };
    LiquidationTransferSchedulesList.prototype.reciverFeedback = function (returnFilter) {
        // this.returnedFilters = returnFilter.data
        this.pageTotal = returnFilter.pagination.TotalRegisters;
        this.page = returnFilter.pagination.CurrentPage;
        this.schedules = new MatTableDataSource(returnFilter.data);
        this.resultsAll = returnFilter.data;
        this.formGroup = returnFilter.formGroup.value;
        this.joinFilter = returnFilter.joinFilter;
        this.params = {
            formGroupValue: JSON.stringify(this.formGroup),
        };
    };
    LiquidationTransferSchedulesList.prototype.dismissAlert = function (targetDate) {
        localStorage.setItem('alertDismissedDate', targetDate);
        this.balanceAlert = null;
    };
    LiquidationTransferSchedulesList.prototype.getAccountBalanceAlert = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var balanceAlert, alertDismissedDate, isDismissed, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.liquidationTransferService.getTodayBalanceAlert().toPromise()];
                    case 1:
                        balanceAlert = _a.sent();
                        if (balanceAlert) {
                            alertDismissedDate = localStorage.getItem('alertDismissedDate');
                            isDismissed = alertDismissedDate === balanceAlert.targetDate;
                            if (!isDismissed)
                                return [2 /*return*/, balanceAlert];
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.log("err", e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    LiquidationTransferSchedulesList.prototype.downloadReport = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var filters, result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        filters = this.joinFilter ? "?" + this.joinFilter : '';
                        console.log('filters', filters);
                        return [4 /*yield*/, this.liquidationTransferService.downloadReport(filters).toPromise()];
                    case 1:
                        result = _a.sent();
                        new JsFileDownloader({
                            url: result.fileUrl,
                        }).start();
                        return [2 /*return*/];
                }
            });
        });
    };
    return LiquidationTransferSchedulesList;
}());
export { LiquidationTransferSchedulesList };
