import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import JsFileDownloader from 'js-file-downloader';
var ModalFormAccumulationManualComponent = /** @class */ (function () {
    function ModalFormAccumulationManualComponent(formBuilder, dialogRef, reportService, snackBar, errorsService, data) {
        this.formBuilder = formBuilder;
        this.dialogRef = dialogRef;
        this.reportService = reportService;
        this.snackBar = snackBar;
        this.errorsService = errorsService;
        this.data = data;
        this.storeControl = new FormControl();
        this.selectedStores = [];
        this.statusControl = new FormControl();
        this.stores = [];
        this.visible = true;
        this.selectable = true;
        this.separatorKeysCodes = [ENTER, COMMA];
    }
    ModalFormAccumulationManualComponent.prototype.ngOnInit = function () {
        this.isArchitect = !!this.data.architect;
        this.formGroup = this.formBuilder.group({
            beginDate: [null, [Validators.required]],
            endDate: [null, [Validators.required]],
        });
        // this.reportService.listStores().subscribe(
        //   response => {
        //     response.map(item => {
        //       this.stores.push(item)
        //     })
        //   },
        //   error => {
        //     console.log(error)
        //   },
        // )
        // this.filteredStores = this.storeControl.valueChanges.pipe(
        //   startWith(''),
        //   map((store: any) => (store ? this._filterStores(store) : this.stores.slice(0, 20))),
        // )
    };
    ModalFormAccumulationManualComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    // private _filterStores (store: string): string[] {
    //   const remove = String(store)
    //   if (typeof remove !== 'string') {
    //     return
    //   }
    //   return this.stores
    //     .filter(c => !this.selectedStores.includes(c))
    //     .filter(c => `${String(c.nomeFantasia)}`.toLowerCase().includes(remove.toLowerCase()))
    //     .slice(0, 20)
    // }
    // remove (value: any): void {
    //   if (value.nomeFantasia) {
    //     const index = this.selectedStores.indexOf(value)
    //     if (index >= 0) {
    //       this.selectedStores.splice(index, 1)
    //     }
    //   }
    // }
    // selected (event: MatAutocompleteSelectedEvent): void {
    //   if (event.option.value.nomeFantasia) {
    //     this.selectedStores.push(event.option.value)
    //     this.storeInput.nativeElement.value = ''
    //     this.storeControl.setValue(null)
    //   }
    // }
    ModalFormAccumulationManualComponent.prototype.submit = function (type) {
        // const storesIds = this.selectedStores.map(c => c.id)
        var _this = this;
        if (!this.formGroup.valid) {
            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
            return false;
        }
        var url = '';
        if (type === 'preRegister') {
            url = 'accumulation-manual/pre-registration';
        }
        else {
            url = 'accumulation-manual';
        }
        var architect;
        if (this.isArchitect) {
            architect = 'isArchitect=true';
        }
        else {
            architect = 'isArchitect=false';
        }
        this.reportService
            .createReport(url + "?" + architect + "&beginDate=" + this.formGroup.value.beginDate + "&endDate=" + this.formGroup.value.endDate)
            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                // eslint-disable-next-line no-new
                new JsFileDownloader({
                    url: response.fileUrl,
                    nameCallback: function () {
                        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                        return 'Relatório de Acúmulo Manual.' + response.fileUrl.split('.').splice(5, 5).join('.');
                    },
                });
                return [2 /*return*/];
            });
        }); })
            .catch(function (error) {
            _this.snackBar.open(error.message);
        });
    };
    return ModalFormAccumulationManualComponent;
}());
export { ModalFormAccumulationManualComponent };
