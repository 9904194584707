<app-logged class="store-list">
  <header class="store-list__header">
    <h2>AGENDAMENTO DE TRANSFERENCIAS PARA LIQUIDAÇÃO DE PARCEIROS</h2>

    <div class="store-list__header__actions">
      <div>
        <button class="btn-primary-light" mat-button (click)="downloadReport()">Exportar essa lista</button>
      </div>
    </div>
  </header>


  <div class="row" *ngIf="balanceAlert">
    <div class="col-sm-12">
      <mat-card style="background-color: rgb(249, 218, 218);border-color: red;">
        <mat-card-content>
          <div class="row">
            <div class="col-6">
              Atenção! Não há saldo para realizar as TEDs no próximo dia útil <strong>{{ balanceAlert?.targetDate | date: 'dd/MM/yyyy'}}</strong>.
            </div>
            <div class="col-6" [align]="'end'">
              <button
                class="btn-primary-light"
                mat-button
                (click)="dismissAlert(balanceAlert?.targetDate)"
              >                
                Dispensar alerta
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div class="row" *ngIf="schedules">
    <div class="col-sm-12">
      <div class="table-responsive">
        <app-form-filter
          maskExample="00.000.000/0000-00"
          actions="form"
          type="transferScheduleList"
          [fieldsForm]="fieldsForm"
          (returnFilter)="reciverFeedback($event)"
          [formGroupValue]="formGroup"
        >
        </app-form-filter>
        <table mat-table [dataSource]="schedules">
          <ng-container matColumnDef="receiver">
            <th mat-header-cell *matHeaderCellDef>Parceiro</th>
            <td mat-cell *matCellDef="let element">
              {{ element.transfer[0]?.storeName }}
            </td>
          </ng-container>

          <ng-container matColumnDef="cnpj">
            <th mat-header-cell *matHeaderCellDef>Cnpj</th>
            <td mat-cell *matCellDef="let element">
              {{ element.transfer[0]?.storeCnpj | mask: '00.000.000/0000-00' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="dueDate">
            <th mat-header-cell *matHeaderCellDef>Data do pagamento</th>
            <td mat-cell *matCellDef="let element">
              {{ element.dueDate | date: 'dd/MM/yyyy' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell 
              *matCellDef="let element"
              [ngClass]="{
                approved: element.success === true,
                reject: element.success === false,
                pending: element.success === 'undefined' || element.success == null              
              }"
            >
              {{ element.success === true ?
                "Concluído" : (
                  element.success === false ?
                  "Erro"
                : "Pendente" )
              }}
            </td>
          </ng-container>

          <ng-container matColumnDef="reason">
            <th mat-header-cell *matHeaderCellDef>Tipo</th>
            <td mat-cell *matCellDef="let element">
              {{
                element.reason == 'liquidation'
                  ? 'Liquidação de parceiros'
                  : 'Transferencias de taxa administrativa'
              }}
            </td>
          </ng-container>

          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef>Criado em</th>
            <td mat-cell *matCellDef="let element">
              {{ element.createdAt | date: 'dd/MM/yyyy' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="totalValue">
            <th mat-header-cell *matHeaderCellDef>Valor</th>
            <td mat-cell *matCellDef="let element">
              {{ totalValue(element.transfer) | currency: 'BRL':'R$' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Ações</th>
            <td mat-cell *matCellDef="let element">
              <button
                mat-icon-button
                matTooltip="Transações"
                matTooltipPosition="left"
                routerLink="/dashboard/liquidation-transctions/{{ element.id }}"
              >
                <mat-icon>swap_horiz</mat-icon>
              </button>
              <button
                mat-icon-button
                matTooltip="Detalhe"
                matTooltipPosition="left"
                routerLink="/dashboard/liquidation-transfer-schedule/details/{{ element.id }}"
              >
                <mat-icon>remove_red_eye</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
          <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
        </table>
        <app-form-filter
          actions="pagination"
          type="transferScheduleList"
          [length]="pageTotal"
          [pageSizeOptions]="pageSizeOptions"
          (returnFilter)="reciverFeedback($event)"
          [formGroupValue]="formGroup"
        ></app-form-filter>
      </div>
    </div>
    
  </div>
</app-logged>
