import { OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { ModalFormMonthlyPaymentComponent } from '../../../../components/modal-form-monthlyPayment/modal-form-monthlyPayment.component';
import { ModalFormAccumulationComponent } from '../../../../components/modal-form-accumulation/modal-form-accumulation.component';
import { ModalFormRescueComponent } from '../../../../components/modal-form-rescue/modal-form-rescue.component';
import { ModalFormBalanceJCoinsComponent } from '../../../../components/modal-form-balanceJCoins/modal-form-balanceJCoins.component';
import { ModalFormReceivablesComponent } from '../../../../components/modal-form-receivables/modal-form-receivables.component';
import { ModalFormGeneralComponent } from '../../../../components/modal-form-general/modal-form-general.component';
import { ModalFormStatementByCustomerComponent } from '../../../../components/modal-form-statementByCustomer/modal-form-statementByCustomer.component';
import { ModalFormTransactionsComponent } from '../../../../components/modal-form-transactions/modal-form-transactions.component';
import { ModalFormTransactionsAdminComponent } from '../../../../components/modal-form-transactions-admin/modal-form-transactions-admin.component';
import { ModalCustomersUniqueComponent } from '../../../../components/modal-form-customersUnique/modal-form-customersUnique.component';
import { ModalFormCustomerMonthBalanceComponent } from '../../../../components/modal-form-customerMonthBalance/modal-form-customerMonthBalance.component';
import { ModalFormTransactionsc2cComponent } from '../../../../components/modal-form-transactions-c2c/modal-form-transactions-c2c.component';
import { ModalFormAccumulationManualComponent } from '../../../../components/modal-form-accumulation-manual/modal-form-accumulation-manual.component';
import { ModalFormAccumulationCardMachineComponent } from '../../../../components/modal-form-accumulation-cardMachine/modal-form-accumulation-cardMachine.component';
import { ModalFormLiquidationTransferDetailedReport } from 'src/app/components/modal-form-liquidation-transfer-detailed-report/modal-form-liquidation-transfer-detailed-report.component';
var ReportsFormComponent = /** @class */ (function () {
    function ReportsFormComponent(errorsService, reportService, authService, snackBar, currencyPipe, dialog, route) {
        this.errorsService = errorsService;
        this.reportService = reportService;
        this.authService = authService;
        this.snackBar = snackBar;
        this.currencyPipe = currencyPipe;
        this.dialog = dialog;
        this.route = route;
        this.isReportToClient = false;
    }
    ReportsFormComponent.prototype.ngOnInit = function () {
        this.userRole = localStorage.getItem('userRole');
        this.storeSelected = JSON.parse(localStorage.getItem('storeSelected'));
        this.architect = this.route.snapshot.paramMap.get('architect');
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        var permission = this.userInfo.permissions ?
            this.userInfo.permissions.filter(function (p) { return p === 'architect_reports'; }) :
            null;
        var ELEMENT_DATA;
        if (this.architect) {
            if (permission.length || this.userRole === 'ADMIN') {
                ELEMENT_DATA = [
                    { value: 'rescue', report: 'Relatório de resgate', category: 'Financeiro' },
                    { value: 'statementByCustomerNew', report: 'Extrato por cliente', category: 'Financeiro' },
                    {
                        value: 'accumulationManual',
                        report: 'Relatório de acúmulo manual',
                        category: 'Financeiro',
                    },
                    { value: 'accumulationTransactions', report: 'Relatório de Acúmulo Transacional APP', category: 'Financeiro' },
                ];
            }
        }
        else if (this.userRole === 'ADMIN') {
            ELEMENT_DATA = [
                { value: 'monthlyPayment', report: 'Relatório de mensalidade', category: 'Financeiro' },
                { value: 'accumulation', report: 'Relatório de Acúmulo APP', category: 'Financeiro' },
                { value: 'accumulationTransactions', report: 'Relatório de Acúmulo Transacional APP', category: 'Financeiro' },
                // {
                //   value: 'accumulationCardMachine',
                //   report: 'Relatório de Acúmulo Cartão',
                //   category: 'Financeiro',
                // },
                // {
                //   value: 'accumulationTransactionsCardMachine',
                //   report: 'Relatório de Acúmulo Transacional Cartão',
                //   category: 'Financeiro',
                // },
                { value: 'rescue', report: 'Relatório de resgate', category: 'Financeiro' },
                { value: 'BalanceJCoins', report: 'Relatório de balanço J-Coins', category: 'Financeiro' },
                // { value: 'general', report: 'Extrato geral', category: 'Financeiro' },
                { value: 'statementByCustomerNew', report: 'Extrato por cliente', category: 'Financeiro' },
                // { value: 'statementByCustomer', report: 'Extrato por cliente', category: 'Financeiro' },
                { value: 'receivables', report: 'Relatório de recebíveis', category: 'Financeiro' },
                { value: 'transactionsAdmin', report: 'Relatório de transação', category: 'Financeiro' },
                {
                    value: 'customerMonthBalance',
                    report: 'Estoque de J-Coins ',
                    category: 'Financeiro',
                },
                {
                    value: 'transactionsc2c',
                    report: 'Relatório de Transferência de Jcoins',
                    category: 'Financeiro',
                },
                {
                    value: 'accumulationManual',
                    report: 'Relatório de acúmulo manual',
                    category: 'Financeiro',
                },
                // {
                //   value: 'fundJcoins',
                //   report: 'Composição de Estoque de JCoins',
                //   category: 'Financeiro',
                // },
                // {
                //   value: 'stockClosing',
                //   report: 'Fechamento de Estoque de JCoins',
                //   category: 'Financeiro',
                // },
                {
                    value: 'consolidatedJcoins',
                    report: 'Estoque de Jcoins',
                    category: 'Financeiro',
                },
                {
                    value: 'accumulationManualPreRegister',
                    report: 'Relatório de acúmulo manual - Pré Cadastro',
                    category: 'Financeiro',
                },
                {
                    value: 'liquidationTransferDetailedReport',
                    report: 'Relatório de Transferencias de Liquidação Detalhado',
                    category: 'Financeiro',
                },
            ];
        }
        else if (this.userRole === 'STOREKEEPER') {
            ELEMENT_DATA = [
                { value: 'transactions', report: 'Relatório de transação', category: 'Financeiro' },
                { value: 'receivables', report: 'Relatório de recebíveis', category: 'Financeiro' },
            ];
        }
        else if (this.userRole === 'STOREKEEPER_SALES' || this.userRole === 'STOREKEEPER_AIRPORT') {
            ELEMENT_DATA = [
                { value: 'transactions', report: 'Relatório de transação', category: 'Financeiro' },
            ];
        }
        this.headersTable = ['relatorio', 'categoria', 'acao'];
        this.dataSource = new MatTableDataSource(ELEMENT_DATA);
    };
    ReportsFormComponent.prototype.formModal = function (value) {
        switch (value) {
            case 'monthlyPayment':
                this.dialog.open(ModalFormMonthlyPaymentComponent);
                break;
            case 'accumulation':
                this.dialog.open(ModalFormAccumulationComponent, {
                    data: {
                        transaction: false,
                    },
                });
                break;
            case 'accumulationTransactions':
                this.dialog.open(ModalFormAccumulationComponent, {
                    data: {
                        transaction: true,
                        architect: !!this.architect,
                    },
                });
                break;
            case 'rescue':
                this.dialog.open(ModalFormRescueComponent, {
                    data: {
                        architect: !!this.architect,
                    },
                });
                break;
            case 'general':
                this.dialog.open(ModalFormGeneralComponent);
                break;
            case 'statementByCustomer':
                this.dialog.open(ModalFormStatementByCustomerComponent, {
                    width: '550px',
                    data: {
                        cpf: '',
                        fullName: '',
                        type: '',
                    },
                });
                break;
            case 'statementByCustomerNew':
                this.dialog.open(ModalFormStatementByCustomerComponent, {
                    width: '550px',
                    data: {
                        cpf: '',
                        fullName: '',
                        type: 'new',
                        architect: !!this.architect,
                    },
                });
                break;
            case 'BalanceJCoins':
                this.dialog.open(ModalFormBalanceJCoinsComponent);
                break;
            case 'receivables':
                this.dialog.open(ModalFormReceivablesComponent);
                break;
            case 'transactions':
                this.dialog.open(ModalFormTransactionsComponent, {
                    data: { storeId: this.storeSelected.storeId },
                });
                break;
            case 'transactionsAdmin':
                this.dialog.open(ModalFormTransactionsAdminComponent);
                break;
            case 'customersUnique':
                this.dialog.open(ModalCustomersUniqueComponent);
                break;
            case 'customerMonthBalance':
                this.dialog.open(ModalFormCustomerMonthBalanceComponent);
                break;
            case 'transactionsc2c':
                this.dialog.open(ModalFormTransactionsc2cComponent);
                break;
            case 'accumulationManual':
                this.dialog.open(ModalFormAccumulationManualComponent, {
                    data: {
                        type: '',
                        architect: !!this.architect,
                    },
                });
                break;
            case 'accumulationManualPreRegister':
                this.dialog.open(ModalFormAccumulationManualComponent, {
                    data: {
                        type: 'preRegister',
                    },
                });
                break;
            case 'accumulationCardMachine':
                this.dialog.open(ModalFormAccumulationCardMachineComponent, {
                    data: {
                        transaction: false,
                    },
                });
                break;
            case 'accumulationTransactionsCardMachine':
                this.dialog.open(ModalFormAccumulationCardMachineComponent, {
                    data: {
                        transaction: true,
                    },
                });
                break;
            case 'liquidationTransferDetailedReport':
                this.dialog.open(ModalFormLiquidationTransferDetailedReport, {
                    data: {
                        transaction: true,
                    },
                });
                break;
        }
    };
    ReportsFormComponent.prototype.applyFilter = function (filterValue) {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    };
    return ReportsFormComponent;
}());
export { ReportsFormComponent };
