import { Component, OnInit, ViewChild } from '@angular/core'
import { MatSnackBar, MatPaginator, MatTableDataSource, MatDialog } from '@angular/material'
import { FormGroup } from '@angular/forms'
import { AirportsService } from '../../../../services/airports.service'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component'
import { ReportService } from '../../../../services/report.service'
import JsFileDownloader from 'js-file-downloader'
import * as moment from 'moment'
import numeral from 'numeral'

@Component({
  selector: 'app-airports-integrations-list',
  templateUrl: './airports-integrations.component.html',
  styleUrls: ['./airports-integrations.component.scss'],
})
export class AirportsIntegrationsListComponent implements OnInit {
  public headersTable: string[] = ['prefix', 'document', 'cnpj', 'nameCli', 'number', 'numberOs', 'amountReceived', 'taxAmountJcoins', 'taxJcoins', 'state', 'observation', 'dateCreatedAt']
  fieldsForm: any
  public returnedFilters: any
  public pageTotal: number
  dataSource: any
  resultsAll: any
  public formGroup: FormGroup
  public params: Params

  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator

  constructor (
    public airportsService: AirportsService,
    public snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
    public reportService: ReportService,
  ) { }

  ngOnInit () {
    // erro
    this.list().catch(err => { console.log(err) })

    this.fieldsForm = [
      {
        field: 'input',
        type: 'text',
        formControlName: 'documentAirportIntegration',
        label: 'CPF/CNPJ',
        placeholder: 'CPF/CNPJ',
      },
      {
        field: 'input',
        type: 'text',
        formControlName: 'cnpjOriginAirportIntegration',
        label: 'CNPJ ORIGEM',
        placeholder: 'CNPJ ORIGEM',
      },
      {
        field: 'input',
        type: 'text',
        formControlName: 'prefixAirportIntegration',
        label: 'Prefixo',
        placeholder: 'Prefixo',
      },
      {
        field: 'input',
        type: 'date',
        label: 'Data Integração Inicio',
        formControlName: 'dateAirportInitIntegration',
      },
      {
        field: 'input',
        type: 'date',
        label: 'Data Integração Fim',
        formControlName: 'dateAirportEndIntegration',
      },
    ]
  }

  async list () {
    // teste
    const now = new Date()
    const firstDay = new Date(now.getFullYear(), now.getMonth(), 1)
    const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0)

    const { results, total } = await this.airportsService
      .integrationsList(`?filters={"page": ${this.page}, "pageSize":${this.pageSize},"filters":[{"fields":["airports_integration.createdAt"], "op": ">=", "value":"${moment(firstDay).format('YYYY-MM-DD')} 00:00:00"}, {"fields":["airports_integration.createdAt"], "op": "<=", "value":"${moment(lastDay).format('YYYY-MM-DD')} 23:59:59"}]}`)
      .catch(err => {
        this.snackBar.open(err.message)
        return { results: [], total: 0 }
      })

    this.dataSource = new MatTableDataSource(results)
    this.pageTotal = total
    this.resultsAll = results
  }

  addMask (document) {
    const apenasNumeros = document.replace(/\D/g, '')
    if (apenasNumeros.length === 11) {
      return apenasNumeros.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
    } else if (apenasNumeros.length > 11) {
      return apenasNumeros.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
    }
    return apenasNumeros
  }

  convertCentsToReal (value) {
    return numeral(value).divide(100).value()
  }

  convertState (state) {
    if (state === 'COMPUTED') return 'COMPUTADO'
    if (state === 'FAILED') return 'FALHA'
  }

  reciverFeedback (returnFilter) {
    this.returnedFilters = returnFilter
    this.pageTotal = returnFilter.total
    this.dataSource = new MatTableDataSource(returnFilter.results)
    this.resultsAll = returnFilter.results
    this.formGroup = returnFilter.formGroup.value
    this.params = {
      formGroupValue: JSON.stringify(this.formGroup),
    }
  }

  async report () {
    await this.reportService.incorporationsConsult().then(async response => {
      // eslint-disable-next-line no-new
      new JsFileDownloader({
        url: response.fileUrl,
        nameCallback: () => {
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          return 'Consulta Base.' + response.fileUrl.split('.').splice(5, 5).join('.')
        },
      })
    })
  }
}
