import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { Params } from '@angular/router';
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component';
import JsFileDownloader from 'js-file-downloader';
import * as moment from 'moment';
import numeral from 'numeral';
var AirportsIntegrationsNotEligibleListComponent = /** @class */ (function () {
    function AirportsIntegrationsNotEligibleListComponent(airportsService, snackBar, dialog, reportService) {
        this.airportsService = airportsService;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.reportService = reportService;
        this.headersTable = ['action', 'prefix', 'document', 'cnpj', 'nameCli', 'number', 'numberOs', 'amountReceived', 'taxAmountJcoins', 'taxJcoins', 'state', 'dateCreatedAt'];
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    }
    AirportsIntegrationsNotEligibleListComponent.prototype.ngOnInit = function () {
        // erro
        this.list().catch(function (err) { console.log(err); });
        this.fieldsForm = [
            {
                field: 'input',
                type: 'text',
                formControlName: 'documentAirportIntegrationNotEligible',
                label: 'CPF/CNPJ',
                placeholder: 'CPF/CNPJ',
            },
            {
                field: 'input',
                type: 'text',
                formControlName: 'cnpjOriginAirportIntegrationNotEligible',
                label: 'CNPJ ORIGEM',
                placeholder: 'CNPJ ORIGEM',
            },
            {
                field: 'input',
                type: 'text',
                formControlName: 'prefixAirportIntegrationNotEligible',
                label: 'Prefixo',
                placeholder: 'Prefixo',
            },
            {
                field: 'input',
                type: 'date',
                label: 'Data Integração Inicio',
                formControlName: 'dateAirportInitIntegrationNotEligible',
            },
            {
                field: 'input',
                type: 'date',
                label: 'Data Integração Fim',
                formControlName: 'dateAirportEndIntegrationNotEligible',
            },
        ];
    };
    AirportsIntegrationsNotEligibleListComponent.prototype.list = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var now, firstDay, lastDay, _a, results, total;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        now = new Date();
                        firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
                        lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
                        return [4 /*yield*/, this.airportsService
                                .integrationsNotEligible("?filters={\"page\": " + this.page + ", \"pageSize\":" + this.pageSize + ",\"filters\":[{\"fields\":[\"airports_integration.createdAt\"], \"op\": \">=\", \"value\":\"" + moment(firstDay).format('YYYY-MM-DD') + " 00:00:00\"}, {\"fields\":[\"airports_integration.createdAt\"], \"op\": \"<=\", \"value\":\"" + moment(lastDay).format('YYYY-MM-DD') + " 23:59:59\"}]}")
                                .catch(function (err) {
                                _this.snackBar.open(err.message);
                                return { results: [], total: 0 };
                            })];
                    case 1:
                        _a = _b.sent(), results = _a.results, total = _a.total;
                        this.dataSource = new MatTableDataSource(results);
                        this.pageTotal = total;
                        this.resultsAll = results;
                        return [2 /*return*/];
                }
            });
        });
    };
    AirportsIntegrationsNotEligibleListComponent.prototype.addMask = function (document) {
        var apenasNumeros = document.replace(/\D/g, '');
        if (apenasNumeros.length === 11) {
            return apenasNumeros.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        }
        else if (apenasNumeros.length > 11) {
            return apenasNumeros.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        }
        return apenasNumeros;
    };
    AirportsIntegrationsNotEligibleListComponent.prototype.convertCentsToReal = function (value) {
        return numeral(value).divide(100).value();
    };
    AirportsIntegrationsNotEligibleListComponent.prototype.convertState = function (state) {
        if (state === 'COMPUTED')
            return 'COMPUTADO';
        if (state === 'FELIED')
            return 'FALHA';
    };
    AirportsIntegrationsNotEligibleListComponent.prototype.sendJcoins = function (id, taxAmountJcoins) {
        var _this = this;
        this.dialog
            .open(ModalConfirmComponent, {
            width: '500px',
            data: {
                text: "Deseja creditar JCoins - Valor: " + taxAmountJcoins,
                buttonConfirmText: 'creditar',
                buttonNot: 'não',
            },
        })
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (result && result !== 'cancel') {
                    return [2 /*return*/, this.airportsService.documentsNotEligibleaddJcoins({ airportIntegrationId: id }).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.list().catch(function (err) { console.log(err); });
                                this.snackBar.open('JCoins creditado com sucesso.');
                                return [2 /*return*/];
                            });
                        }); }, function (error) {
                            console.log(error);
                            _this.snackBar.open(error.error.message);
                        })];
                }
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    AirportsIntegrationsNotEligibleListComponent.prototype.reciverFeedback = function (returnFilter) {
        this.returnedFilters = returnFilter;
        this.pageTotal = returnFilter.total;
        this.dataSource = new MatTableDataSource(returnFilter.results);
        this.resultsAll = returnFilter.results;
        this.formGroup = returnFilter.formGroup.value;
        this.params = {
            formGroupValue: JSON.stringify(this.formGroup),
        };
    };
    AirportsIntegrationsNotEligibleListComponent.prototype.report = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.reportService.incorporationsConsult().then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                // eslint-disable-next-line no-new
                                new JsFileDownloader({
                                    url: response.fileUrl,
                                    nameCallback: function () {
                                        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                                        return 'Consulta Base.' + response.fileUrl.split('.').splice(5, 5).join('.');
                                    },
                                });
                                return [2 /*return*/];
                            });
                        }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return AirportsIntegrationsNotEligibleListComponent;
}());
export { AirportsIntegrationsNotEligibleListComponent };
