import { Component, OnInit } from '@angular/core'
import { AuthService } from '../../../../../services/auth.service'
import { MatSnackBar, MatDialog } from '@angular/material'
import { Router, ActivatedRoute } from '@angular/router'
import JsFileDownloader from 'js-file-downloader'
import { ModalConfirmComponent } from '../../../../../components/modal-confirm/modal-confirm.component'
import { CompaniesService } from '../../../../../services/companies.service'

@Component({
  selector: 'app-client-terms-list',
  templateUrl: './client-terms-list.component.html',
  styleUrls: ['./client-terms-list.component.scss'],
})
export class ClientTermsListComponent implements OnInit {
  public headersTable: string[] = ['date', 'documentName', 'document', 'actions']
  public terms: any[] = []
  public document: string
  public type: string

  constructor (
    public authService: AuthService,
    public snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    public companiesService: CompaniesService,
  ) { }

  ngOnInit () {
    this.document = this.route.snapshot.paramMap.get('document')
    this.type = this.route.snapshot.paramMap.get('type')
    this.listTerms()
  }

  listTerms () {
    if (this.type === 'company') {
      this.companiesService.listCompaniesTerms(this.document).then(
        response => {
          this.terms = response.results
        },
        error => {
          console.log(error)
          this.snackBar.open(error.error.message)
        },
      )
    } else {
      this.authService.listCustomerTerms(this.document).then(
        response => {
          this.terms = response.results
        },
        error => {
          console.log(error)
          this.snackBar.open(error.error.message)
        },
      )
    }
  }

  async goBack () {
    if (this.type === 'pre-register-cms') {
      await this.router.navigate(['./dashboard/pre-registration/CMS'])
    } else if (this.type === 'pre-register') {
      await this.router.navigate(['./dashboard/pre-registration/'])
    } else if (this.type === 'client') {
      await this.router.navigate(['./dashboard/clients'])
    } else if (this.type === 'company') {
      await this.router.navigate(['./dashboard/architect-companies'])
    }
  }

  download (event, documentUrl, documentName) {
    event.preventDefault()
    // eslint-disable-next-line no-new
    new JsFileDownloader({
      url: documentUrl,
      nameCallback: () => {
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        return documentName
      },
    })
  }

  remove (id) {
    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: 'Deseja remover esse Termo?',
          buttonConfirmText: 'Remover',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          if (this.type === 'company') {
            return this.companiesService.deleteCompanyTerm(id).then(
              async response => {
                if (response.isDeleted) {
                  this.listTerms()
                  this.snackBar.open('Termo removido com sucesso.')
                }
              },
              error => {
                console.log(error)
                this.snackBar.open(error.error.message)
              },
            )
          }
        } else {
          return this.authService.deleteCustomerTerm(id).then(
            async response => {
              if (response.isDeleted) {
                this.listTerms()
                this.snackBar.open('Termo removido com sucesso.')
              }
            },
            error => {
              console.log(error)
              this.snackBar.open(error.error.message)
            },
          )
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }
}
