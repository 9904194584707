import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { startWith, map } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import JsFileDownloader from 'js-file-downloader';
var ModalFormRescueComponent = /** @class */ (function () {
    function ModalFormRescueComponent(formBuilder, dialogRef, reportService, snackBar, errorsService, architect) {
        this.formBuilder = formBuilder;
        this.dialogRef = dialogRef;
        this.reportService = reportService;
        this.snackBar = snackBar;
        this.errorsService = errorsService;
        this.architect = architect;
        this.storeControl = new FormControl();
        this.selectedStores = [];
        this.statusControl = new FormControl();
        this.selectedStatus = [];
        this.stores = [];
        this.status = [];
        this.visible = true;
        this.selectable = true;
        this.separatorKeysCodes = [ENTER, COMMA];
    }
    ModalFormRescueComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isArchitect = !!this.architect.architect;
        this.formGroup = this.formBuilder.group({
            beginDate: [null, [Validators.required]],
            endDate: [null, [Validators.required]],
            storesInput: [null, []],
            statusInput: [null, []],
        });
        this.reportService.listStores().subscribe(function (response) {
            response.map(function (item) {
                _this.stores.push(item);
            });
        }, function (error) {
            console.log(error);
        });
        this.reportService.getStatus().subscribe(function (response) {
            Object.keys(response).map(function (k) {
                if (k === 'APPROVED' || k === 'CANCELED') {
                    _this.status.push({ key: k, value: response[k] });
                }
            });
            _this.status.sort(function (a, b) {
                return a.value < b.value ? -1 : a.value > b.value ? 1 : 0;
            });
        });
        this.filteredStores = this.storeControl.valueChanges.pipe(startWith(''), map(function (store) { return (store ? _this._filterStores(store) : _this.stores.slice(0, 20)); }));
        this.filteredStatus = this.statusControl.valueChanges.pipe(startWith(''), map(function (status) { return (status ? _this._filterStatus(status) : _this.status.slice(0, 20)); }));
    };
    ModalFormRescueComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    ModalFormRescueComponent.prototype._filterStores = function (store) {
        var _this = this;
        var remove = String(store);
        if (typeof remove !== 'string') {
            return;
        }
        return this.stores
            .filter(function (c) { return !_this.selectedStores.includes(c); })
            .filter(function (c) { return ("" + String(c.nomeFantasia)).toLowerCase().includes(remove.toLowerCase()); })
            .slice(0, 20);
    };
    ModalFormRescueComponent.prototype._filterStatus = function (status) {
        var _this = this;
        var remove = String(status);
        if (typeof remove !== 'string') {
            return;
        }
        return this.status
            .filter(function (c) { return !_this.selectedStatus.includes(c); })
            .filter(function (c) { return ("" + String(c.value)).toLowerCase().includes(remove.toLowerCase()); })
            .slice(0, 20);
    };
    ModalFormRescueComponent.prototype.remove = function (value) {
        if (value.nomeFantasia) {
            var index = this.selectedStores.indexOf(value);
            if (index >= 0) {
                this.selectedStores.splice(index, 1);
            }
        }
        if (value.key) {
            var index = this.selectedStatus.indexOf(value);
            if (index >= 0) {
                this.selectedStatus.splice(index, 1);
            }
        }
    };
    ModalFormRescueComponent.prototype.selected = function (event) {
        if (event.option.value.nomeFantasia) {
            this.selectedStores.push(event.option.value);
            this.storeInput.nativeElement.value = '';
            this.storeControl.setValue(null);
        }
        if (event.option.value.key) {
            this.selectedStatus.push(event.option.value);
            this.statusInput.nativeElement.value = '';
            this.statusControl.setValue(null);
        }
    };
    ModalFormRescueComponent.prototype.submit = function () {
        var _this = this;
        var storesIds = this.selectedStores.map(function (c) { return c.id; });
        var status = this.selectedStatus.map(function (c) { return c.key; });
        if (!this.formGroup.valid) {
            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
            return false;
        }
        var architect;
        if (this.isArchitect) {
            architect = 'isArchitect=true';
        }
        else {
            architect = 'isArchitect=false';
        }
        if (this.selectedStores.length && this.selectedStatus.length) {
            this.filter = "rescue?" + architect + "&transactionsState=" + status + "&storesIds=" + storesIds + "&beginDate=" + this.formGroup.value.beginDate + "&endDate=" + this.formGroup.value.endDate;
        }
        else if (this.selectedStores.length) {
            this.filter = "rescue?" + architect + "&storesIds=" + storesIds + "&beginDate=" + this.formGroup.value.beginDate + "&endDate=" + this.formGroup.value.endDate;
        }
        else if (this.selectedStatus.length) {
            this.filter = "rescue?" + architect + "&transactionsState=" + status + "&beginDate=" + this.formGroup.value.beginDate + "&endDate=" + this.formGroup.value.endDate;
        }
        else {
            this.filter = "rescue?" + architect + "&beginDate=" + this.formGroup.value.beginDate + "&endDate=" + this.formGroup.value.endDate;
        }
        this.reportService
            .createReport(this.filter)
            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                // eslint-disable-next-line no-new
                new JsFileDownloader({
                    url: response.fileUrl,
                    nameCallback: function () {
                        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                        return 'Relatório de Resgate.' + response.fileUrl.split('.').splice(5, 5).join('.');
                    },
                });
                return [2 /*return*/];
            });
        }); })
            .catch(function (error) {
            _this.snackBar.open(error.message);
        });
    };
    return ModalFormRescueComponent;
}());
export { ModalFormRescueComponent };
