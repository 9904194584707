import { Component, OnInit } from '@angular/core'
import { MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material'
import { ActivatedRoute, Router } from '@angular/router'
import { LiquidationTransferService, TransferRetryRequestDTO } from 'src/app/services/liquidationTransfer.service'
import { ModalLiquidationTransferDetails } from '../modal-liquidation-transfer-details/modal-liquidation-transfer-details.component'
import { ModalTransferLogs } from '../modal-transfer-logs/modal-transfer-logs.component'
import { ModalTransferManualComponent } from 'src/app/components/modal-transfer-manual/modal-transfer-manual.component'
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component'
import { Location } from '@angular/common'

@Component({
  selector: 'app-transfer-schedule-list',
  templateUrl: './schedule-details.component.html',
  styleUrls: ['./schedule-details.component.scss'],
})
export class LiquidationTransferScheduleDetails implements OnInit {
  public schedule: any
  public transfers: any
  public retryButtonsClicked: any = {}
  public headersTable: string[] = ['createdAt', 'totalValue', 'status', 'executedAt', 'actions']

  public scheduleId

  constructor(
    private readonly liquidationTransferService: LiquidationTransferService,
    private readonly route: ActivatedRoute,
    private readonly dialog: MatDialog,
    public snackBar: MatSnackBar,
    private router: Router,
    private readonly location: Location,
  ) {
    this.scheduleId = this.route.snapshot.paramMap.get('scheduleId')
  }

  ngOnInit(): void {
    this.loadScheduleInfo()
  }

  async loadScheduleInfo() {
    const result = await this.liquidationTransferService.getScheduleDetail(this.scheduleId).toPromise()
    this.schedule = result
    this.transfers = new MatTableDataSource(result.transfer)
  }

  async openTransferDetails(transfer) {
    await this.dialog.open(ModalLiquidationTransferDetails, {
      width: '500px',
      data: transfer,
    }).afterClosed().toPromise()
  }

  async openTransferLogs(transfer) {
    await this.dialog.open(ModalTransferLogs, {
      minWidth: '600px',
      width: '80%',
      data: transfer.logs,
    }).afterClosed().toPromise()
  }

  async retryFailedTransfer(transferData) {
    const result = await this.dialog.open(ModalTransferManualComponent, {
      width: '500px',
      data: {
        text: 'Caso queira atualizar os dados bancários nesse momento, após, será necessário atualizar no cadastro do parceiro.',
        buttonConfirmText: 'enviar',
        buttonNot: 'Cancelar',
        ...transferData,
      },
    }).afterClosed().toPromise()
    if (result && result !== 'not') {
      this.retryButtonsClicked[transferData.id] = true

      const retryPaymentData: TransferRetryRequestDTO = {
        transferId: transferData.id,
        bankAccountNumber: result.bankAccountNumber ?
          result.bankAccountNumber :
          transferData.bankAccountNumber,
        bankNumber: result.bankNumber ? result.bankNumber : transferData.bankNumber,
        bankAccountDigit: result.bankAccountDigit ?
          result.bankAccountDigit :
          transferData.bankAccountDigit,
        bankAgency: result.bankAgency ? result.bankAgency : transferData.bankAgency,
        bankAccountType: transferData.bankAccountType,
        bankPixKey: transferData.bankPixKey,
        propagateChangesToNextTransferSchedules: result.propagateChangesToNextTransferSchedules,
        propagateChangesToStore: result.propagateChangesToStore,
        storeCnpj: transferData.storeCnpj,
      }

      try {
        const result = await this.liquidationTransferService.retryPayments(retryPaymentData).toPromise()
        const currentUrl = this.router.url;
        this.snackBar.open('Transferencia enviada para processamento')
        setTimeout(() => {
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([currentUrl]);
          });
        }, 1000);
       
      } catch (err) {
        this.retryButtonsClicked[transferData.id] = false
        this.snackBar.open('Erro ao tentar realizar a transferência')
      }
    }
  }

  goBack(){
    this.location.back()
  }
}
