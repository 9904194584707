import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, of, throwError } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import { environment } from '../../environments/environment'
@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  public loading

  constructor (private readonly http: HttpClient) {}

  listTransactions (filter?): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/transactions${filter || ''}`).pipe(
      tap(_ => this.log('cms/transactions')),
      catchError(this.handleError),
    )
  }

  listTransactionsByClosing (closingID): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/closings/${closingID}/transactions`).pipe(
      tap(_ => this.log('cms/closings/transactions')),
      catchError(this.handleError),
    )
  }

  cancelTransaction (transactionID, data): Observable<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/cms/transactions/${transactionID}/cancel`, data)
      .pipe(
        tap(_ => this.log('cms/transactions/cancel')),
        catchError(this.handleError),
      )
  }

  cancelTransactionC2C (transactionID, data): Observable<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/cms/transactions/c2c/${transactionID}/cancel`, data)
      .pipe(
        tap(_ => this.log('/cms/transactions/c2c/cancel')),
        catchError(this.handleError),
      )
  }

  changeStateTransactionManual (transactionID, data): Observable<any> {
    return this.http
      .put<any>(
        `${environment.apiUrl}/cms/transactions/${transactionID}/manual-transactions/state-machine`,
        data,
    )
      .pipe(
        tap(_ => this.log('cms/transactions/manual-transactions/state-machine')),
        catchError(this.handleError),
      )
  }

  listTransactionsById (transactionID): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/transactions/${transactionID}`).pipe(
      tap(_ => this.log('cms/transactions')),
      catchError(this.handleError),
    )
  }

  listTransactionsByCustomers (storeId): Observable<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/customers/transactions?storeId=${storeId}`)
      .pipe(
        tap(_ => this.log('customers/transactions')),
        catchError(this.handleError),
      )
  }

  addTransaction (data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/cms/transactions`, data).pipe(
      tap(_ => this.log('cms/transactions')),
      catchError(this.handleError),
    )
  }

  createInternationalTransactionFromIntent (data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/cms/international-payment-intent/transactions`, data).pipe(
      tap(_ => this.log('cms/international-payment-intent/transactions')),
      catchError(this.handleError),
    )
  }

  createInternationalPaymentIntent (data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/cms/international-payment-intent`, data).pipe(
      tap(_ => this.log('cms/international-payment-intent')),
      catchError(this.handleError),
    )
  }

  getInternationalInfoForTransactions (storeId: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/transactions/international-info/`, { params: { storeId } }).pipe(
      tap(_ => this.log('cms/transactions/international-info/')),
      catchError(this.handleError),
    )
  }

  uploadAuthorizationDocument (pathAwsUpload, data): Observable<any> {
    const headers = {
      'Content-Type': 'multipart/form-data',
    }
    return this.http
      .put<any>(`${pathAwsUpload}`, data, { headers })
      .pipe(
        tap(_ => this.log('cms/transactions/documents')),
        catchError(this.handleError),
      )
  }

  getStatus (): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/transactions/status`).pipe(
      tap(_ => this.log('cms/transactions/status')),
      catchError(this.handleError),
    )
  }

  listTransactionsReservation (filter?): Observable<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/transactions/reservation${filter || ''}`)
      .pipe(
        tap(_ => this.log('cms/transactions/reservation')),
        catchError(this.handleError),
      )
  }

  async transactionsListByReserve (paymentId: string): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/transactions/payment-future/${paymentId}`)
      .toPromise()
  }

  async transactionsReserveFindOne (paymentId: string): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/transactions/reserve/${paymentId}`)
      .toPromise()
  }

  getPaymentsStatus (): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/transactions/payments/status`).pipe(
      tap(_ => this.log('cms/transactions/payments/status')),
      catchError(this.handleError),
    )
  }

  async paymentAlter (paymentId: string, newValue): Promise<any> {
    return this.http
      .put<any>(`${environment.apiUrl}/cms/transactions/payment/${paymentId}`, {
      totalValue: newValue,
    })
      .toPromise()
  }

  async transactionSendEvent (id, data): Promise<void> {
    await this.http
      .post<any>(`${environment.apiUrl}/cms/transactions/send-event/${id}`, data)
      .toPromise()
  }

  async transactionsMembershipPlanByCustomer (planId, customerId, filter?): Promise<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}/cms/transactions/membership-plan/${planId}/customer/${customerId}${
          filter || ''
        }`,
    )
      .toPromise()
  }

  async update (id, data): Promise<void> {
    await this.http.put<any>(`${environment.apiUrl}/cms/transactions/${id}`, data).toPromise()
  }

  async getLiquidationPaymentInfo (id): Promise<void> {
    await this.http.get<any>(`${environment.apiUrl}/cms/liquidation-transfer/transactions/${id}`).toPromise()
  }

  private handleError (error: any) {
    return throwError(error)
  }

  /** Log a HeroService message with the MessageService */
  private log (message: string) {}
}
