import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Buffer } from 'buffer';

export type TransferRetryRequestDTO = {
  transferId: string;
  propagateChangesToNextTransferSchedules: boolean;
  propagateChangesToStore: boolean;
  bankAccountNumber: string;
  bankNumber: string;
  bankAccountDigit: string;
  bankAgency: string;
  bankAccountType: string;
  bankPixKey: string;
  storeCnpj: string;
}

@Injectable({
  providedIn: 'root'
})
export class LiquidationTransferService {

  

  constructor(private http: HttpClient) { }

  listShedules(filters): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/liquidation-transfer/schedule${filters || ''}`)
      .pipe(
        tap(_ => this.log('/schedule')),
        catchError(this.handleError)
      )
  }

  listShedulesfromStore(storeId, filters): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/liquidation-transfer/schedule-from-store/${storeId}${filters || ''}`)
      .pipe(
        tap(_ => this.log('cms/liquidation-transfer/schedule-from-store')),
        catchError(this.handleError)
      )
  }

  getScheduleDetail(id): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/liquidation-transfer/schedule/${id}`)
      .pipe(
        tap(_ => this.log('/schedule/:id')),
        catchError(this.handleError)
      )
  }

  retryPayments(retryPaymentData: TransferRetryRequestDTO){
    return this.http.post<any>(`${environment.apiUrl}/cms/liquidation-transfer/transfer/retry-payments`, retryPaymentData)
      .pipe(
        tap(_ => this.log('/schedule/:id')),
        catchError(this.handleError)
      )
  }

  getLiquidationTransferPermissions(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/liquidation-transfer/liquidation-transfer-permissions`).pipe(
      tap(_ => this.log('cms/liquidation-transfer/liquidation-transfer-permissions')),
      catchError(this.handleError),
    )
  }

  updateLiquidationTransferPermissions(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/cms/liquidation-transfer/update-liquidation-transfer-permissions`,data).pipe(
      tap(_ => this.log('cms/liquidation-transfer/update-liquidation-transfer-permissions')),
      catchError(this.handleError),
    )
  }

  updateLiquidationTransferSettings(data): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/cms/liquidation-transfer/settings`,data).pipe(
      tap(_ => this.log('cms/liquidation-transfer/settings')),
      catchError(this.handleError),
    )
  }

  getLiquidationTransferSettings(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/liquidation-transfer/settings`).pipe(
      tap(_ => this.log('cms/liquidation-transfer/settings')),
      catchError(this.handleError),
    )
  }

  getTodayBalanceAlert(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/liquidation-transfer/today-balance-alert`).pipe(
      tap(_ => this.log('cms/liquidation-transfer/today-balance-alert')),
      catchError(this.handleError),
    )
  }

  updateScheduledLiquidationTransfersBankAccount(storeId): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/cms/liquidation-transfer/update-scheduled-bank-account?storeId=${storeId}`,{}).pipe(
      tap(_ => this.log('cms/liquidation-transfer/update-scheduled-bank-account')),
      catchError(this.handleError),
    )
  }

  checkIfFutureSchedulesExistsForStore(storeId): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/liquidation-transfer/check-future-schedules?storeId=${storeId}`).pipe(
      tap(_ => this.log('cms/liquidation-transfer/check-future-schedules')),
      catchError(this.handleError),
    )
  }

  downloadReport(filters): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/reports/liquidation-transfer-general-report${filters || ''}`)
      .pipe(
        tap(_ => this.log('cms/reports/liquidation-transfer-general-report')),
        catchError(this.handleError)
      )
  }

  private handleError(error: any) { 
    return throwError(error);
  }   

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
  }
  
}
