import { Component, OnInit, ViewChild } from '@angular/core'
import { Router, ActivatedRoute, ParamMap } from '@angular/router'
import { StoreService } from 'src/app/services/store.service'
import { UtilService } from 'src/app/services/util.service'
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material'
import { LiquidationTransferService } from 'src/app/services/liquidationTransfer.service'
import { Location } from '@angular/common'

@Component({
  selector: 'app-liquidation-transactions-list',
  templateUrl: './liquidation-transactions-list.component.html',
  styleUrls: ['./liquidation-transactions-list.component.scss'],
})
export class LiquidationTransactionsList implements OnInit {
  public headersTable: string[] = ['orderNumber', 'customerName', 'createdAt', 'totalValue', 'jcoinsValue', 'storeTransferAmount']
  public dataSource: any
  public scheduleId: any
  public schedule: any
  public storeName: any
  public dueDate: any
  public isAdmin = false
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  constructor (
    public storeService: StoreService,
    private readonly route: ActivatedRoute,
    public utilService: UtilService,
    private readonly liquidationTransferService: LiquidationTransferService,
    private readonly location: Location,
  ) { }

  ngOnInit () {
    this.scheduleId = this.route.snapshot.paramMap.get('scheduleId')
    const userRole = localStorage.getItem('userRole')
    this.isAdmin = userRole == 'ADMIN'
    this.renderScheduleList()
  }

  async renderScheduleList () {
    await this.liquidationTransferService.listShedules(`?scheduleId=${this.scheduleId}`)
      .toPromise()
      .then(async (response) => {
        this.schedule = response.data.find(() => true).transfer.find(() => true)
        this.storeName = this.schedule.storeName
        this.dueDate = response.data.find(() => true).dueDate
        if(this.schedule.reason === 'administrative_tax')
          this.headersTable.splice(1, 0, "storeName")
        const groupedData = response.data[0].transaction.reduce((result, item) => {
          const { orderNumber, storeTransferAmount, administrativeTaxValue, ...rest } = item
          const existingItem = result.find(entry => entry.orderNumber === orderNumber)

          if (existingItem) {
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            existingItem.storeTransferAmount += parseFloat(storeTransferAmount)
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            existingItem.administrativeTaxValue += parseFloat(administrativeTaxValue)
          } else {
            result.push({
              orderNumber,
              storeTransferAmount: parseFloat(storeTransferAmount),
              administrativeTaxValue: parseFloat(administrativeTaxValue),
              ...rest,
            })
          }
          return result
        }, [])

        this.dataSource = new MatTableDataSource(groupedData)
        this.dataSource.paginator = this.paginator
        this.dataSource.sort = this.sort
        this.paginator._intl.itemsPerPageLabel = 'Itens por página'
      })
  }

  goBack(){
    this.location.back()
  }
}
