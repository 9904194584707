import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { Validators } from '@angular/forms';
import { base64ToBlob } from 'base64-blob';
import { CropperDialogComponent } from '../../../../components/cropper-dialog/cropper-dialog.component';
var ClientFormAddComponent = /** @class */ (function () {
    function ClientFormAddComponent(errorsService, zipCodeService, formBuilder, storeService, authService, router, route, snackBar, _dialog, _cd, imageCompress, customerAttendantService) {
        this.errorsService = errorsService;
        this.zipCodeService = zipCodeService;
        this.formBuilder = formBuilder;
        this.storeService = storeService;
        this.authService = authService;
        this.router = router;
        this.route = route;
        this.snackBar = snackBar;
        this._dialog = _dialog;
        this._cd = _cd;
        this.imageCompress = imageCompress;
        this.customerAttendantService = customerAttendantService;
        this.states = [];
        this.plans = [];
        this.firstPaymentEnabled = false;
        this.planSelectEnabled = false;
        this.frontImageInvalid = false;
        this.behindImageInvalid = false;
        this.kinds = [];
        this.attendants = [];
        this.initForm();
    }
    ClientFormAddComponent.prototype.ngOnInit = function () {
        // empty
    };
    ClientFormAddComponent.prototype.initForm = function () {
        var _this = this;
        this.storeID = this.route.snapshot.paramMap.get('storeID');
        this.formGroup = this.formBuilder.group({
            cpf: [null, [Validators.required]],
            firstName: [null, [Validators.required]],
            lastName: [null, [Validators.required]],
            gender: [null, [Validators.required]],
            blockTransaction: [null, [Validators.required]],
            blockTransactionJcoins: [null, [Validators.required]],
            birthDate: [null, []],
            emailAddress: [null, [Validators.required, Validators.email]],
            phoneNumber: [null, [Validators.required]],
            membershipEnabled: [null, [Validators.required]],
            plan: [null, [Validators.required]],
            firstPayment: [null, []],
            kind: [null, [Validators.required]],
            attendant: [null, []],
        });
        this.authService.clientKindList().then(function (response) {
            _this.kinds = response.results;
        }, function (error) {
            console.log(error);
            _this.snackBar.open(error.error.message);
        });
        this.customerAttendantService.list().then(function (response) {
            _this.attendants = response.results;
        }, function (error) {
            console.log(error);
            _this.snackBar.open(error.error.message);
        });
    };
    ClientFormAddComponent.prototype.changeTypePlan = function (event) {
        if (event.value == 'recurrent') {
            this.firstPaymentEnabled = true;
            this.formGroup.get('firstPayment').setValidators([Validators.required]);
            this.formGroup.get('firstPayment').updateValueAndValidity();
        }
        else {
            this.firstPaymentEnabled = false;
            this.formGroup.get('firstPayment').setValidators(null);
            this.formGroup.get('firstPayment').setErrors(null);
        }
    };
    ClientFormAddComponent.prototype.changeMembershipEnabled = function (event) {
        if (event.value === true) {
            this.planSelectEnabled = true;
            this.formGroup.get('plan').setValidators([Validators.required]);
            this.formGroup.get('plan').updateValueAndValidity();
        }
        else {
            this.firstPaymentEnabled = false;
            this.planSelectEnabled = false;
            this.formGroup.get('plan').setValidators(null);
            this.formGroup.get('plan').setErrors(null);
            this.formGroup.get('plan').setValue('none');
        }
    };
    ClientFormAddComponent.prototype.selectFileDocumentFront = function (event) {
        var _this = this;
        var originalDocumentFrontImageUrl = event.target.files[0];
        var reader = new FileReader();
        reader.onloadend = function (e) {
            _this.originalDocumentFrontImage = reader.result;
        };
        try {
            reader.readAsDataURL(originalDocumentFrontImageUrl);
        }
        catch (e) {
            this.snackBar.open(e.message);
        }
        this.croppedDocumentFrontImage = null;
        this._dialog
            .open(CropperDialogComponent, {
            data: { img: event, config: { width: 38 * 16, height: 10 * 16 } },
            width: 650,
            disableClose: true,
        })
            .afterClosed.toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!result) return [3 /*break*/, 2];
                        this.croppedDocumentFrontImage = result.img.dataURL;
                        this.documentFrontImagePosition = result.config;
                        _a = this;
                        return [4 /*yield*/, base64ToBlob(this.croppedDocumentFrontImage)];
                    case 1:
                        _a.documentFrontImageData = _b.sent();
                        this.frontImageInvalid = !this.croppedDocumentFrontImage;
                        this._cd.markForCheck();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); })
            .catch(function (error) {
            _this.snackBar.open(error.message);
        });
    };
    ClientFormAddComponent.prototype.editDocumentFrontImage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var img, _a, result, _b, error_1;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 7, , 8]);
                        if (!this.originalDocumentFrontImage) return [3 /*break*/, 1];
                        _a = this.originalDocumentFrontImage;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.getBase64ImageFromUrl(this.croppedDocumentFrontImage)];
                    case 2:
                        _a = _c.sent();
                        _c.label = 3;
                    case 3:
                        img = _a;
                        return [4 /*yield*/, this._dialog
                                .open(CropperDialogComponent, {
                                data: {
                                    img: img,
                                    config: tslib_1.__assign({}, this.documentFrontImagePosition, { width: 38 * 16, height: 10 * 16 }),
                                },
                                width: 650,
                                disableClose: true,
                            })
                                .afterClosed.toPromise()];
                    case 4:
                        result = _c.sent();
                        if (!result) return [3 /*break*/, 6];
                        this.croppedDocumentFrontImage = result.img.dataURL;
                        this.documentFrontImagePosition = result.config;
                        _b = this;
                        return [4 /*yield*/, base64ToBlob(this.croppedDocumentFrontImage)];
                    case 5:
                        _b.documentFrontImageData = _c.sent();
                        this.frontImageInvalid = !this.croppedDocumentFrontImage;
                        this._cd.markForCheck();
                        _c.label = 6;
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        error_1 = _c.sent();
                        this.snackBar.open(error_1.message);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    ClientFormAddComponent.prototype.selectFileDocumentBehind = function (event) {
        var _this = this;
        var originalDocumentBehindImageUrl = event.target.files[0];
        var reader = new FileReader();
        reader.onloadend = function (e) {
            _this.originalDocumentBehindImage = reader.result;
        };
        try {
            reader.readAsDataURL(originalDocumentBehindImageUrl);
        }
        catch (e) {
            this.snackBar.open(e.message);
        }
        this.croppedDocumentBehindImage = null;
        this._dialog
            .open(CropperDialogComponent, {
            data: { img: event, config: { width: 38 * 16, height: 10 * 16 } },
            width: 650,
            disableClose: true,
        })
            .afterClosed.toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!result) return [3 /*break*/, 2];
                        this.croppedDocumentBehindImage = result.img.dataURL;
                        this.documentBehindImagePosition = result.config;
                        _a = this;
                        return [4 /*yield*/, base64ToBlob(this.croppedDocumentBehindImage)];
                    case 1:
                        _a.documentBehindImageData = _b.sent();
                        this.behindImageInvalid = !this.croppedDocumentBehindImage;
                        this._cd.markForCheck();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); })
            .catch(function (error) {
            _this.snackBar.open(error.message);
        });
    };
    ClientFormAddComponent.prototype.editDocumentBehindImage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var img, _a, result, _b, error_2;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 7, , 8]);
                        if (!this.originalDocumentBehindImage) return [3 /*break*/, 1];
                        _a = this.originalDocumentBehindImage;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.getBase64ImageFromUrl(this.croppedDocumentBehindImage)];
                    case 2:
                        _a = _c.sent();
                        _c.label = 3;
                    case 3:
                        img = _a;
                        return [4 /*yield*/, this._dialog
                                .open(CropperDialogComponent, {
                                data: {
                                    img: img,
                                    config: tslib_1.__assign({}, this.documentBehindImagePosition, { width: 38 * 16, height: 10 * 16 }),
                                },
                                width: 650,
                                disableClose: true,
                            })
                                .afterClosed.toPromise()];
                    case 4:
                        result = _c.sent();
                        if (!result) return [3 /*break*/, 6];
                        this.croppedDocumentBehindImage = result.img.dataURL;
                        this.documentBehindImagePosition = result.config;
                        _b = this;
                        return [4 /*yield*/, base64ToBlob(this.croppedDocumentBehindImage)];
                    case 5:
                        _b.documentBehindImageData = _c.sent();
                        this.behindImageInvalid = !this.croppedDocumentBehindImage;
                        this._cd.markForCheck();
                        _c.label = 6;
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        error_2 = _c.sent();
                        this.snackBar.open(error_2.message);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    ClientFormAddComponent.prototype.getBase64ImageFromUrl = function (imageUrl) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, blob;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(imageUrl, {
                            method: 'GET',
                            mode: 'cors',
                            cache: 'no-cache',
                            keepalive: false,
                            referrer: 'origin-when-cross-origin',
                        })];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, res.blob()];
                    case 2:
                        blob = _a.sent();
                        return [2 /*return*/, new Promise(function (resolve, reject) {
                                var reader = new FileReader();
                                reader.addEventListener('load', function () {
                                    resolve(reader.result);
                                }, false);
                                reader.readAsDataURL(blob);
                            })];
                }
            });
        });
    };
    ClientFormAddComponent.prototype.removeImage = function (input) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (input === 'documentFront') {
                    this.readUrlDocumentFront = '';
                    this.originalDocumentFrontImage = undefined;
                    this.croppedDocumentFrontImage = undefined;
                    this.documentFrontImagePosition = undefined;
                    this.documentFrontImageData = undefined;
                    this.frontImageInvalid = true;
                }
                if (input === 'documentBehind') {
                    this.readUrlDocumentBehind = '';
                    this.originalDocumentBehindImage = undefined;
                    this.croppedDocumentBehindImage = undefined;
                    this.documentBehindImagePosition = undefined;
                    this.documentBehindImageData = undefined;
                    this.behindImageInvalid = true;
                }
                return [2 /*return*/];
            });
        });
    };
    ClientFormAddComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, response, data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.frontImageInvalid = !this.croppedDocumentFrontImage;
                        this.behindImageInvalid = !this.croppedDocumentBehindImage;
                        if (!this.formGroup.valid) {
                            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                            return [2 /*return*/, false];
                        }
                        if (!(this.croppedDocumentFrontImage && this.documentFrontImageData)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.authService.getS3Url('documentFront')];
                    case 1:
                        response = _a.sent();
                        this.readUrlDocumentFront = response.readUrl;
                        return [4 /*yield*/, this.authService.uploadToS3(response.uploadUrl, this.documentFrontImageData)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        if (!(this.croppedDocumentBehindImage && this.documentBehindImageData)) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.authService.getS3Url('documentBehind')];
                    case 4:
                        response = _a.sent();
                        this.readUrlDocumentBehind = response.readUrl;
                        return [4 /*yield*/, this.authService.uploadToS3(response.uploadUrl, this.documentBehindImageData)];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6:
                        data = {
                            cpf: this.formGroup.value.cpf,
                            firstName: this.formGroup.value.firstName,
                            lastName: this.formGroup.value.lastName,
                            gender: this.formGroup.value.gender,
                            blockTransaction: this.formGroup.value.blockTransaction,
                            blockTransactionJcoins: this.formGroup.value.blockTransactionJcoins,
                            emailAddress: this.formGroup.value.emailAddress,
                            phoneNumber: this.formGroup.value.phoneNumber,
                            membershipEnabled: this.formGroup.value.membershipEnabled,
                            imgDocumentFront: this.readUrlDocumentFront,
                            imgDocumentBehind: this.readUrlDocumentBehind,
                            customerKindId: this.formGroup.value.kind,
                            attendantCustomerId: this.formGroup.value.attendant,
                        };
                        if (this.formGroup.value.birthDate) {
                            data.birthDate = this.formGroup.value.birthDate;
                        }
                        if (this.formGroup.value.membershipEnabled) {
                            data.plan = this.formGroup.value.plan;
                        }
                        if (this.formGroup.value.plan == 'recurrent') {
                            data.firstPayment = this.formGroup.value.firstPayment;
                        }
                        return [4 /*yield*/, this.authService
                                .signupClient(data)
                                .toPromise()
                                .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    if (response.isCreated) {
                                        this.snackBar.open('Cliente criado com sucesso.');
                                        this.router.navigate(['./dashboard/clients']);
                                    }
                                    return [2 /*return*/];
                                });
                            }); }, function (error) {
                                var message = error.error.message;
                                if (error.error.message.keyword) {
                                    message = error.error.message.message;
                                }
                                _this.snackBar.open(message);
                            })];
                    case 7:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return ClientFormAddComponent;
}());
export { ClientFormAddComponent };
