import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
var LiquidationTransactionsList = /** @class */ (function () {
    function LiquidationTransactionsList(storeService, route, utilService, liquidationTransferService, location) {
        this.storeService = storeService;
        this.route = route;
        this.utilService = utilService;
        this.liquidationTransferService = liquidationTransferService;
        this.location = location;
        this.headersTable = ['orderNumber', 'customerName', 'createdAt', 'totalValue', 'jcoinsValue', 'storeTransferAmount'];
        this.isAdmin = false;
    }
    LiquidationTransactionsList.prototype.ngOnInit = function () {
        this.scheduleId = this.route.snapshot.paramMap.get('scheduleId');
        var userRole = localStorage.getItem('userRole');
        this.isAdmin = userRole == 'ADMIN';
        this.renderScheduleList();
    };
    LiquidationTransactionsList.prototype.renderScheduleList = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.liquidationTransferService.listShedules("?scheduleId=" + this.scheduleId)
                            .toPromise()
                            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var groupedData;
                            return tslib_1.__generator(this, function (_a) {
                                this.schedule = response.data.find(function () { return true; }).transfer.find(function () { return true; });
                                this.storeName = this.schedule.storeName;
                                this.dueDate = response.data.find(function () { return true; }).dueDate;
                                if (this.schedule.reason === 'administrative_tax')
                                    this.headersTable.splice(1, 0, "storeName");
                                groupedData = response.data[0].transaction.reduce(function (result, item) {
                                    var orderNumber = item.orderNumber, storeTransferAmount = item.storeTransferAmount, administrativeTaxValue = item.administrativeTaxValue, rest = tslib_1.__rest(item, ["orderNumber", "storeTransferAmount", "administrativeTaxValue"]);
                                    var existingItem = result.find(function (entry) { return entry.orderNumber === orderNumber; });
                                    if (existingItem) {
                                        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                                        existingItem.storeTransferAmount += parseFloat(storeTransferAmount);
                                        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                                        existingItem.administrativeTaxValue += parseFloat(administrativeTaxValue);
                                    }
                                    else {
                                        result.push(tslib_1.__assign({ orderNumber: orderNumber, storeTransferAmount: parseFloat(storeTransferAmount), administrativeTaxValue: parseFloat(administrativeTaxValue) }, rest));
                                    }
                                    return result;
                                }, []);
                                this.dataSource = new MatTableDataSource(groupedData);
                                this.dataSource.paginator = this.paginator;
                                this.dataSource.sort = this.sort;
                                this.paginator._intl.itemsPerPageLabel = 'Itens por página';
                                return [2 /*return*/];
                            });
                        }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    LiquidationTransactionsList.prototype.goBack = function () {
        this.location.back();
    };
    return LiquidationTransactionsList;
}());
export { LiquidationTransactionsList };
