<app-logged class="store-list">
  <header class="store-list__header">
    <h2>
      TRANSAÇÕES DO AGENDAMENTO 
      <span *ngIf="isAdmin">- Parceiro: {{ storeName }}</span>
      <span *ngIf="!isAdmin">- Agendado para: {{ dueDate | date:'dd/MM/yyyy' }}</span>
    </h2>
    <div class="store-list__header__actions">
      <button
        class="btn-primary-light"
        mat-button
        (click)="goBack()"
      >
        VOLTAR
      </button>
    </div>
  </header>
  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="orderNumber">
        <th mat-header-cell *matHeaderCellDef>Número da Transação</th>
        <td mat-cell *matCellDef="let element">
          {{ element.orderNumber }}
        </td>
      </ng-container>
      <ng-container matColumnDef="customerName">
        <th mat-header-cell *matHeaderCellDef>Nome do Cliente</th>
        <td mat-cell *matCellDef="let element">
          {{ element.customerName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="storeName" *ngIf="schedule.reason == 'administrative_tax'">
        <th mat-header-cell *matHeaderCellDef>Nome do parceiro</th>
        <td mat-cell *matCellDef="let element">
          {{ element.storeName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>Data</th>
        <td mat-cell *matCellDef="let element">
          {{ element.createdAt | date: 'dd/MM/yyyy' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="totalValue">
        <th mat-header-cell *matHeaderCellDef>Valor total</th>
        <td mat-cell *matCellDef="let element">
          {{ element.totalValue | currency: 'BRL':'R$' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="jcoinsValue">
        <th mat-header-cell *matHeaderCellDef>Jcoins</th>
        <td mat-cell *matCellDef="let element">
          {{ element.jcoinsValue }}
        </td>
      </ng-container>
      <ng-container matColumnDef="storeTransferAmount">
        <th mat-header-cell *matHeaderCellDef>
          Valor Transferência
          <span *ngIf="isAdmin">
            ({{ schedule.reason == 'liquidation' ? 'Liquidação de parceiros' : 'Taxa administrativa' }})
          </span>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ (schedule.reason == 'liquidation' ? element.storeTransferAmount : element.administrativeTaxValue) | currency: 'BRL':'R$' }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
    <!-- Angular 8 pagination -->
    <mat-paginator
      [pageSizeOptions]="[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</app-logged>
