<app-logged class="store-form">
  <header class="store-list__header">
    <h2>INFORMAÇÕES DA TRANSAÇÃO</h2>
    <div class="store-list__header__actions">
      <button *ngIf="isAdmin" class="btn-primary-light" mat-button (click)="update()">
        Salvar
      </button>
      <!--<button class="btn-primary-light" mat-button mat-button printSectionId="print-section"
        [printStyle]="{h3 : {'width': '100%'}, h2 : {'border': 'solid 1px'}}" ngxPrint>Imprimir</button>-->
      <button class="btn-primary-light" mat-button (click)="goBack()">VOLTAR</button>
    </div>
  </header>
  <div class="table-responsive">
    <table mat-table [dataSource]="transactionsInfo">
      <ng-container matColumnDef="orderNumber">
        <th mat-header-cell *matHeaderCellDef>Transação</th>
        <td mat-cell *matCellDef="let element" class="formatMain">{{ element.orderNumber }}</td>
      </ng-container>
      <ng-container matColumnDef="totalValue">
        <th mat-header-cell *matHeaderCellDef>Valor</th>
        <td mat-cell *matCellDef="let element" class="formatMain">
          {{ element.totalValue | currency: 'BRL' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="pointsApplied">
        <th mat-header-cell *matHeaderCellDef>Valor em J-Coins</th>
        <td mat-cell *matCellDef="let element" class="formatMain">
          {{ element.pointsApplied }}
        </td>
      </ng-container>
      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="{
            approved: element.state == 'APPROVED',
            pending: element.state == 'PENDING',
            reject: element.state == 'REJECTED',
            canceled: element.state == 'CANCELED'
          }"
        >
          {{ convertStatus(element.state) }}
          <span
            *ngIf="
              (element.state == 'CANCELED' || element.state == 'REJECTED') &&
              (element.reason || element.rejectReason)
            "
          >
            Motivo: {{ element.reason ? element.reason : element.rejectReason }}
          </span>
          <span *ngIf="isAdmin && element.internalErrorDescription">
            <a
              style="text-decoration: underline; cursor: pointer; margin-bottom: 2px"
              (click)="openModalErrorDetails(element)"
            >
              Detalhes
            </a>
          </span>

          <span *ngIf="isAdmin && element.errorTransactionLivelo">
            <a
              style="text-decoration: underline; cursor: pointer; margin-bottom: 2px"
              (click)="openModalLiveloErrorDetails(element)"
            >
              Detalhes
            </a>
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Ação</th>
        <td mat-cell *matCellDef="let element">
          <button
            *ngIf="showCancelButton"
            mat-icon-button
            matTooltip="Cancelar"
            (click)="openDialogCancelTransaction(element)"
            matTooltipPosition="left"
          >
            <mat-icon class="canceltransaction">cancel</mat-icon>
          </button>
          <button
            *ngIf="showRejectButton"
            mat-icon-button
            matTooltip="Rejeitar transação"
            (click)="openDialogRejectTransaction(element)"
            matTooltipPosition="left"
          >
            <mat-icon class="canceltransaction">cancel</mat-icon>
          </button>
          <button
            *ngIf="element.isManual && element.state == 'PENDING' && isAdmin"
            mat-icon-button
            matTooltip="Aprovar"
            (click)="openDialogConfirmation(element)"
            matTooltipPosition="left"
          >
            <mat-icon class="approvedtransaction">add_task</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="Atualizar"
            (click)="refresh()"
            matTooltipPosition="left"
            routerLink="/dashboard/transactions/detail/{{ element.id }}"
          >
            <mat-icon>update</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
  </div>

  <h4>Informações</h4>
  <div class="table-responsive">
    <table mat-table [dataSource]="transactionsInfoGeneral">
      <ng-container matColumnDef="storeName">
        <th mat-header-cell *matHeaderCellDef>Parceiro</th>
        <td mat-cell *matCellDef="let element">{{ element.storeNomeFantasia }}</td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Descrição</th>
        <td mat-cell *matCellDef="let element">
          {{ element.description != 'dynamic' ? element.description : 'QRcode' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="subStore">
        <th mat-header-cell *matHeaderCellDef>Sub-empresa</th>
        <td style="padding-right: 10px" mat-cell *matCellDef="let element">
          <mat-select
            (selectionChange)="changeSubStore($event.value)"
            value="{{ element.subStore }}"
          >
            <mat-option *ngFor="let mg of subStore" [value]="mg.id">{{
              mg.subStoreName
            }}</mat-option>
          </mat-select>
        </td>
      </ng-container>
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>Data</th>
        <td mat-cell *matCellDef="let element">{{ element.createdAt | date: 'dd/MM/yyyy' }}</td>
      </ng-container>
      <ng-container matColumnDef="customer">
        <th mat-header-cell *matHeaderCellDef>Cliente</th>
        <td width="200" mat-cell *matCellDef="let element">
          {{ element.customer.customerFullName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="info">
        <th mat-header-cell *matHeaderCellDef></th>
        <td
          mat-cell
          *matCellDef="let element"
          matTooltip="CPF: {{ element.customer.customerCpf | mask: '000.000.000-00' }}"
        >
          <button mat-icon-button (click)="detail(element.customer)">
            <mat-icon class="smallicon">info</mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="membershipPlan">
        <th mat-header-cell *matHeaderCellDef>Plano Membership</th>
        <td mat-cell *matCellDef="let element">{{ element.membershipPlan }}</td>
      </ng-container>
      <ng-container *ngIf="isManual && isAdmin" matColumnDef="balance">
        <th mat-header-cell *matHeaderCellDef>Saldo J-Coins do Cliente</th>
        <td mat-cell *matCellDef="let element">{{ element.balance }}</td>
      </ng-container>
      <ng-container *ngIf="isManual" matColumnDef="approveDoc">
        <th mat-header-cell *matHeaderCellDef>Documento de Autorização</th>
        <td mat-cell *matCellDef="let element">
          <a *ngIf="element.approveDoc != ''" href="{{ element.approveDoc }}" target="_blank"
            >Visualizar documento</a
          >
          <span *ngIf="element.approveDoc == ''">Sem documento</span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="headersTableGeneral"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTableGeneral"></tr>
    </table>
  </div>

  <h4 *ngIf="isNonPartner">Estabelecimento não parceiro</h4>
  <div class="table-responsive" *ngIf="isNonPartner">
    <table mat-table [dataSource]="transactionsInfoNonPartner">
      <ng-container matColumnDef="nomeFantasiaWithoutStore">
        <th mat-header-cell *matHeaderCellDef>Nome fantasia</th>
        <td mat-cell *matCellDef="let element">{{ element.nomeFantasiaWithoutStore }}</td>
      </ng-container>
      <ng-container matColumnDef="cnpjWithoutStore">
        <th mat-header-cell *matHeaderCellDef>CNPJ</th>
        <td mat-cell *matCellDef="let element">
          {{ element.cnpjWithoutStore | mask: '00.000.000/0000-00' }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="headersTableNonPartner"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTableNonPartner"></tr>
    </table>
  </div>

  <div *ngIf="recipientCustomerId" class="table-responsive">
    <table mat-table [dataSource]="recipientCustomer">
      <ng-container matColumnDef="recipient">
        <th mat-header-cell *matHeaderCellDef>Beneficiário</th>
        <td mat-cell *matCellDef="let element">
          {{ element.recipientFullName }}
          -
          {{ element.recipientCpf | mask: '000.000.000-00' }}
          <button
            *ngIf="isAdmin"
            mat-icon-button
            matTooltip="Informações"
            (click)="detailRecipient(element)"
            matTooltipPosition="left"
          >
            <mat-icon class="smallicon">info</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="headersTableCustomerRecipient"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTableCustomerRecipient"></tr>
    </table>
  </div>

  <div *ngIf="fundId" class="table-responsive">
    <table mat-table [dataSource]="funds">
      <ng-container matColumnDef="fundName">
        <th mat-header-cell *matHeaderCellDef>Nome Fundo</th>
        <td mat-cell *matCellDef="let element">{{ element.fundName }}</td>
      </ng-container>
      <ng-container matColumnDef="document">
        <th mat-header-cell *matHeaderCellDef>CNPJ</th>
        <td mat-cell *matCellDef="let element">
          {{ element.fundDocument | mask: '00.000.000/0000-00' }}
          <button
            *ngIf="isAdmin"
            mat-icon-button
            matTooltip="Informações Fundo"
            (click)="detailFund(element.fundId)"
            matTooltipPosition="left"
          >
            <mat-icon class="smallicon">info</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="headersTableFunds"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTableFunds"></tr>
    </table>
  </div>

  <h4 *ngIf="!isManual">Cartão de Crédito</h4>
  <div *ngIf="!isManual" style="margin-bottom: 10px" class="table-responsive">
    <table mat-table [dataSource]="transactionsInfoCard">
      <ng-container matColumnDef="netValue">
        <th mat-header-cell *matHeaderCellDef>Vlr. Cartão</th>
        <td mat-cell *matCellDef="let element">
          {{ element.netValue | currency: 'BRL':'R$' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="installments">
        <th mat-header-cell *matHeaderCellDef>Parcelas</th>
        <td
          mat-cell
          *matCellDef="let element"
          matTooltip="{{ element.paidInstallments }}/{{ element.installments }} parcelas pagas"
          matTooltipPosition="left"
          matTooltipDisabled="{{ !element.installments }}"
        >
          <span *ngIf="element.installments">
            {{ element.paidInstallments }}/{{ element.installments }}
            <button
              *ngIf="isAdmin"
              mat-icon-button
              (click)="listInstallments(element.installments, element.installmentsData)"
            >
              <mat-icon class="smallicon">info</mat-icon>
            </button>
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="cardBrand">
        <th mat-header-cell *matHeaderCellDef>Bandeira</th>
        <td mat-cell *matCellDef="let element">
          <img
            *ngIf="element.cardBrand && element.type !== 'C2C'"
            class="card"
            width="30"
            src="assets/icon/brands/{{ formatBrand(element.cardBrand) }}.png"
          />
        </td>
      </ng-container>

      <ng-container matColumnDef="rewards">
        <th mat-header-cell *matHeaderCellDef>Qtde J-Coins (cashback)</th>
        <td
          mat-cell
          *matCellDef="let element"
          matTooltip="{{ element.totalRewarded }} Jcoins confirmados de {{
            element.rewards
          }} futuros"
          matTooltipPosition="left"
          matTooltipDisabled="{{ !element.rewards }}"
        >
          <span *ngIf="element.rewards">
            {{ element.totalRewarded }}/{{ element.rewards }}

            <button
              *ngIf="isAdmin"
              mat-icon-button
              (click)="listInstallments(element.installments, element.installmentsData)"
            >
              <mat-icon class="smallicon">info</mat-icon>
            </button>
          </span>
          <span *ngIf="!element.rewards"> 0 </span>
        </td>
        <!-- <td mat-cell *matCellDef="let element">{{ element.rewards }}</td> -->
      </ng-container>
      <ng-container matColumnDef="cashbackValue">
        <th mat-header-cell *matHeaderCellDef>Valor J-Coins (cashback)</th>
        <td mat-cell *matCellDef="let element">
          {{ element.cashbackValue | currency: 'BRL':'R$' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="cardNumber">
        <th mat-header-cell *matHeaderCellDef>
          Número do cartão
          {{ isAdditionalCard ? 'Adicional' : '' }}
          {{ isVirtualCard ? 'Virtual' : '' }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.cardNumber }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="headersTableInfosCard"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTableInfosCard"></tr>
    </table>
  </div>
  <div *ngIf="!isManual">
    <div class="table-responsive">
      <table mat-table [dataSource]="transactionsInfoCardTwo">
        <ng-container matColumnDef="taxAdministrative">
          <th mat-header-cell *matHeaderCellDef>Taxa Administrativa JHSF (% )</th>
          <td mat-cell *matCellDef="let element">{{ element.taxAdministrative }} %</td>
        </ng-container>
        <ng-container matColumnDef="taxAdministrativeAmount">
          <th mat-header-cell *matHeaderCellDef>Valor Taxa Administrativa JHSF (R$)</th>
          <td mat-cell *matCellDef="let element">
            {{ element.taxAdministrativeAmount | currency: 'BRL':'R$' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="storeSplitAmount">
          <th mat-header-cell *matHeaderCellDef>Valor de Repasse Liquido (R$)</th>
          <td mat-cell *matCellDef="let element">
            {{ element.storeSplitAmount | currency: 'BRL':'R$' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="paymentProvider">
          <th mat-header-cell *matHeaderCellDef>Provedor de pagamento</th>
          <td mat-cell *matCellDef="let element">
            {{ element.paymentProvider == 'zoop' ? 'Zoop' : 'Pagare' }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="headersTableInfosCardTwo"></tr>
        <tr mat-row *matRowDef="let row; columns: headersTableInfosCardTwo"></tr>
      </table>
    </div>
    <!-- <div class="table-responsive">
      <table mat-table [dataSource]="transactionsInfoCardThree">
        <ng-container matColumnDef="isInternational">
          <th mat-header-cell *matHeaderCellDef>É uma Transação Internacional?</th>
          <td mat-cell *matCellDef="let element">
            {{ element.isInternational ? 'Sim' : 'Não' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="isVirtualCard">
          <th mat-header-cell *matHeaderCellDef>É um Cartão Virtual?</th>
          <td mat-cell *matCellDef="let element">
            {{ element.isVirtualCard ? 'Sim' : 'Não' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="isAdditionalCard">
          <th mat-header-cell *matHeaderCellDef>É um Cartão Adicional?</th>
          <td mat-cell *matCellDef="let element">
            {{ element.isAdditionalCard ? 'Sim' : 'Não' }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="headersTableInfosCardThree"></tr>
        <tr mat-row *matRowDef="let row; columns: headersTableInfosCardThree"></tr>
      </table>
    </div> -->
  </div>
  <div *ngIf="internationalTransactionData.length > 0">
    <h4>Informações de Câmbio</h4>
    <div class="table-responsive">
      <table mat-table [dataSource]="internationalTransactionData">
        <!-- Define columns -->
        <ng-container matColumnDef="currency">
          <th mat-header-cell *matHeaderCellDef>Moeda de origem</th>
          <td mat-cell *matCellDef="let element">{{ element?.currency }}</td>
        </ng-container>
        <ng-container matColumnDef="totalValue">
          <th mat-header-cell *matHeaderCellDef>Valor de origem</th>
          <td mat-cell *matCellDef="let element">
            {{ element.internationalCurrencyValue | currency: element.currency }}
          </td>
        </ng-container>
        <ng-container matColumnDef="totalValueWithTaxes">
          <th mat-header-cell *matHeaderCellDef>Valor em Reais (sem impostos)</th>
          <td mat-cell *matCellDef="let element">
            {{ element.totalValueWithoutTaxes / 100 | currency: 'BRL':'R$' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="purchaseFeeValue">
          <th mat-header-cell *matHeaderCellDef>Valor dos impostos</th>
          <td mat-cell *matCellDef="let element">
            {{ element.purchaseFeeValue | currency: 'BRL':'R$' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="purchaseFeePercent">
          <th mat-header-cell *matHeaderCellDef>Percentual dos impostos</th>
          <td mat-cell *matCellDef="let element">{{ element.purchaseFeePercent }}%</td>
        </ng-container>
        <ng-container matColumnDef="currencyQuote">
          <th mat-header-cell *matHeaderCellDef>Cotação da Moeda</th>
          <td mat-cell *matCellDef="let element">
            {{ element.currencyQuote | currency: 'BRL':'R$' }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="headerInternationalTransaction"></tr>
        <tr mat-row *matRowDef="let row; columns: headerInternationalTransaction"></tr>
      </table>
    </div>
  </div>

  <h4>J-Coins</h4>
  <div class="table-responsive">
    <table mat-table [dataSource]="transactionsInfoJC">
      <ng-container matColumnDef="pointsApplied">
        <th mat-header-cell *matHeaderCellDef>Qtde Utilizada</th>
        <td mat-cell *matCellDef="let element">{{ element.pointsApplied }}</td>
      </ng-container>
      <ng-container matColumnDef="discountValue">
        <th mat-header-cell *matHeaderCellDef>Valor</th>
        <td mat-cell *matCellDef="let element">
          {{ element.discountValue | currency: 'BRL':'R$' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="taxRewardsTransaction">
        <th mat-header-cell *matHeaderCellDef>Taxa Administrativa JHSF (% )</th>
        <td mat-cell *matCellDef="let element">{{ element.taxRewardsTransaction }} %</td>
      </ng-container>
      <ng-container matColumnDef="taxRewardsTransactionAmount">
        <th mat-header-cell *matHeaderCellDef>Valor Taxa Administrativa JHSF (R$)</th>
        <td mat-cell *matCellDef="let element">
          {{ element.taxRewardsTransactionAmount | currency: 'BRL':'R$' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="storeTransferAmount">
        <th mat-header-cell *matHeaderCellDef>Repasse Liquido (R$)</th>
        <td mat-cell *matCellDef="let element">
          {{ element.storeTransferAmount | currency: 'BRL':'R$' }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="headersTableInfosJC"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTableInfosJC"></tr>
    </table>
  </div>
  <div *ngIf="type === 'LIVELO'">
    <h4>Informações Livelo</h4>
    <div class="table-responsive">
      <table mat-table [dataSource]="liveloData">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>ID Livelo</th>
          <td mat-cell *matCellDef="let element">{{ element.transactionIdLivelo }}</td>
        </ng-container>
        <ng-container matColumnDef="points">
          <th mat-header-cell *matHeaderCellDef>Pontos Livelo gerados</th>
          <td mat-cell *matCellDef="let element">
            {{ element.pointsLivelo}}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="headersLiveloData"></tr>
        <tr mat-row *matRowDef="let row; columns: headersLiveloData"></tr>
      </table>
    </div>
  </div>
  <div *ngIf="liquidationDetails">
    <h4>Repasse de J-Coins</h4>

    <div class="table-responsive">
      <table mat-table [dataSource]="liquidationDetails">
        <ng-container matColumnDef="isAdministrativeTaxPaid">
          <th mat-header-cell *matHeaderCellDef>Status Pagamento da taxa administrativa</th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="{
              approved: element.isAdministrativeTaxPaid === true,
              reject: element.isAdministrativeTaxPaid === false,
              pending:
                element.isAdministrativeTaxPaid == 'undefined' ||
                element.isAdministrativeTaxPaid == null
            }"
          >
            {{
              element.isAdministrativeTaxPaid === true
                ? 'Concluído'
                : element.isAdministrativeTaxPaid === false
                ? 'Erro'
                : 'Pendente'
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="administrativeTaxDueDate">
          <th mat-header-cell *matHeaderCellDef>
            Pagamento da Taxa Administrativa JHSF Agendado para
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.administrativeTaxDueDate | date: 'dd/MM/yyyy' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="administrativeTaxPaidAt">
          <th mat-header-cell *matHeaderCellDef>Taxa Administrativa JHSF paga em</th>
          <td mat-cell *matCellDef="let element">
            {{ element.administrativeTaxPaidAt | date: 'dd/MM/yyyy HH:mm' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="scheduleLink">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <a
              [routerLink]="
                '/dashboard/liquidation-transfer-schedule/details/' +
                element.administrativeTaxScheduleId
              "
            >
              Detalhes do pagamento
            </a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="headersTableLiquidationDetailOne"></tr>
        <tr mat-row *matRowDef="let row; columns: headersTableLiquidationDetailOne"></tr>
      </table>
    </div>
    <div class="table-responsive">
      <table mat-table [dataSource]="liquidationDetails">
        <ng-container matColumnDef="isStoreTransferPaid">
          <th mat-header-cell *matHeaderCellDef>Status Repasse líquido</th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="{
              approved: element.isStoreTransferPaid === true,
              reject: element.isStoreTransferPaid === false,
              pending:
                element.isStoreTransferPaid == 'undefined' || element.isStoreTransferPaid == null
            }"
          >
            {{
              element.isStoreTransferPaid === true
                ? 'Concluído'
                : element.isStoreTransferPaid === false
                ? 'Erro'
                : 'Pendente'
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="storeTransferDueDate">
          <th mat-header-cell *matHeaderCellDef>Pagamento do Repasse líquido Agendado para</th>
          <td mat-cell *matCellDef="let element">
            {{ element.storeTransferDueDate | date: 'dd/MM/yyyy' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="storeTransferPaidAt">
          <th mat-header-cell *matHeaderCellDef>Repasse líquido Pago em</th>
          <td mat-cell *matCellDef="let element">
            {{ element.storeTransferPaidAt | date: 'dd/MM/yyyy HH:mm' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="scheduleLink">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <a
              [routerLink]="
                '/dashboard/liquidation-transfer-schedule/details/' +
                element.storeTransferScheduleId
              "
            >
              Detalhes do repasse
            </a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="headersTableLiquidationDetailTwo"></tr>
        <tr mat-row *matRowDef="let row; columns: headersTableLiquidationDetailTwo"></tr>
      </table>
    </div>
  </div>
  <h4 *ngIf="nfs.length > 0">NFs</h4>
  <div class="table-responsive" *ngIf="nfs.length > 0">
    <table mat-table [dataSource]="nfs">
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Tipo da NF</th>
        <td mat-cell *matCellDef="let element">
          {{ getNfType(element.entityId) }}
        </td>
      </ng-container>
      <ng-container matColumnDef="totvsCode">
        <th mat-header-cell *matHeaderCellDef>Código Totvs</th>
        <td mat-cell *matCellDef="let element">
          {{ element.totvsCode }}
        </td>
      </ng-container>
      <ng-container matColumnDef="verificationCode">
        <th mat-header-cell *matHeaderCellDef>Código de Verificação</th>
        <td mat-cell *matCellDef="let element">
          {{ element.codeVerification }}
        </td>
      </ng-container>
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>Data de emissão</th>
        <td mat-cell *matCellDef="let element">
          {{ element.createdAt | date: 'dd/MM/yyyy HH:mm' }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTableNfs"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTableNfs"></tr>
    </table>
  </div>

  <h4 *ngIf="userRole == 'ADMIN'">Configurações</h4>
  <div class="table-responsive" *ngIf="userRole == 'ADMIN'">
    <table mat-table [dataSource]="transactionsInfoConfig">
      <ng-container matColumnDef="pointsConversionFactorOut">
        <th mat-header-cell *matHeaderCellDef>Valor J-Coin na saída</th>
        <td mat-cell *matCellDef="let element">
          {{ element.pointsConversionFactorOut | currency: 'BRL':'R$' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="pointsConversionFactorIn">
        <th mat-header-cell *matHeaderCellDef>Valor J-Coin na entrada</th>
        <td mat-cell *matCellDef="let element">
          {{ element.pointsConversionFactorIn | currency: 'BRL':'R$' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="maximumInstallmentsPerTransaction">
        <th mat-header-cell *matHeaderCellDef>Número Máximo Parcela</th>
        <td mat-cell *matCellDef="let element">{{ element.maximumInstallmentsPerTransaction }}</td>
      </ng-container>
      <ng-container matColumnDef="minimumTransactionValue">
        <th mat-header-cell *matHeaderCellDef>Valor Mínimo</th>
        <td mat-cell *matCellDef="let element">
          {{ element.minimumTransactionValue | currency: 'BRL':'R$' }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="headersTableConfigs"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTableConfigs"></tr>
    </table>
  </div>
</app-logged>
