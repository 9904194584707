import * as tslib_1 from "tslib";
//create a component
import { OnInit } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
var StoreLiquidationTransfersComponent = /** @class */ (function () {
    function StoreLiquidationTransfersComponent(liquidationTransferService) {
        this.liquidationTransferService = liquidationTransferService;
        this.headersTable = ['dueDate', 'status', 'totalValue', 'paidAt', 'actions'];
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
        this.storeSelected = JSON.parse(localStorage.getItem('storeSelected'));
    }
    StoreLiquidationTransfersComponent.prototype.ngOnInit = function () {
        this.renderScheduleList();
        this.fieldsForm = [
            {
                field: 'input',
                type: 'date',
                label: 'Agendado para - Data Início',
                formControlName: 'startDueDateTransferScheduleList',
            },
            {
                formControlName: 'endDueDateTransferScheduleList',
                field: 'input',
                type: 'date',
                label: 'Agendado para - Data Final',
            },
            {
                field: 'input',
                type: 'date',
                label: 'Pago em - Data Início',
                formControlName: 'startPaidAtDateTransferScheduleList',
            },
            {
                formControlName: 'endPaidAtDateTransferScheduleList',
                field: 'input',
                type: 'date',
                label: 'Pago em - Data Final',
            },
        ];
    };
    StoreLiquidationTransfersComponent.prototype.renderScheduleList = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, data, pagination;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.liquidationTransferService.listShedulesfromStore(this.storeSelected.storeId, "?&page=" + this.page + "&limit=" + this.pageSize).toPromise()];
                    case 1:
                        _a = _b.sent(), data = _a.data, pagination = _a.pagination;
                        this.schedules = new MatTableDataSource(data);
                        this.pageTotal = pagination.TotalRegisters;
                        return [2 /*return*/];
                }
            });
        });
    };
    StoreLiquidationTransfersComponent.prototype.totalValue = function (transfer) {
        return transfer.length ?
            transfer.find(function () { return true; }).totalValue
            : 0;
    };
    StoreLiquidationTransfersComponent.prototype.reciverFeedback = function (returnFilter) {
        // this.returnedFilters = returnFilter.data
        this.pageTotal = returnFilter.pagination.TotalRegisters;
        this.page = returnFilter.pagination.CurrentPage;
        this.schedules = new MatTableDataSource(returnFilter.data);
        this.resultsAll = returnFilter.data;
        this.formGroup = returnFilter.formGroup.value;
        this.params = {
            formGroupValue: JSON.stringify(this.formGroup),
        };
    };
    return StoreLiquidationTransfersComponent;
}());
export { StoreLiquidationTransfersComponent };
