import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef, ElementRef } from '@angular/core';
import { Validators, FormControl } from '@angular/forms';
import { base64ToBlob } from 'base64-blob';
import { map, startWith } from 'rxjs/operators';
import { CropperDialogComponent } from '../../../../components/cropper-dialog/cropper-dialog.component';
import JsFileDownloader from 'js-file-downloader';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import moment from 'moment';
var PreRegistrationFormComponent = /** @class */ (function () {
    function PreRegistrationFormComponent(errorsService, formBuilder, preRegistrationService, snackBar, router, route, zipCodeService, _dialog, _cd, imageCompress, authService, storeService, location, companiesService) {
        this.errorsService = errorsService;
        this.formBuilder = formBuilder;
        this.preRegistrationService = preRegistrationService;
        this.snackBar = snackBar;
        this.router = router;
        this.route = route;
        this.zipCodeService = zipCodeService;
        this._dialog = _dialog;
        this._cd = _cd;
        this.imageCompress = imageCompress;
        this.authService = authService;
        this.storeService = storeService;
        this.location = location;
        this.companiesService = companiesService;
        this.params = { formGroupValue: [] };
        this.frontImageInvalid = false;
        this.behindImageInvalid = false;
        this.firstPaymentEnabled = false;
        this.planSelectEnabled = false;
        this.stores = [];
        this.store = [];
        this.storeName = '';
        this.jcoinsInvalid = false;
        this.disapprovedDocument = false;
        this.selectedCompanies = [];
        this.companyControl = new FormControl();
        this.selectedStatus = [];
        this.separatorKeysCodes = [ENTER, COMMA];
        this.companies = [];
        this.notCpnj = false;
        this.showPlan = false;
        this.otherProfession = false;
        this.storeReferralOthers = false;
    }
    PreRegistrationFormComponent.prototype.ngOnInit = function () {
        this.preRegistrationID = this.route.snapshot.paramMap.get('preRegistrationID');
        this.typePreRegistration = this.route.snapshot.paramMap.get('type');
        this.initForm().catch(function (err) { return console.log(err); });
        if (this.typePreRegistration !== 'architects' || (this.typePreRegistration === 'architects' && this.preRegistrationID)) {
            this.showPlan = true;
        }
    };
    PreRegistrationFormComponent.prototype.initForm = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.route.queryParams.subscribe(function (params) {
                            _this.params.formGroupValue = params.formGroupValue;
                        });
                        this.formGroup = this.formBuilder.group({
                            cpf: [null, [Validators.required]],
                            firstName: [null, [Validators.required]],
                            lastName: [null, [Validators.required]],
                            gender: [null, []],
                            birthDate: [null, []],
                            emailAddress: [null, [Validators.email]],
                            phoneNumber: [null, []],
                            isMembership: [null, []],
                            planMembership: [null, []],
                            firstPayment: [null, []],
                            store: [null, []],
                            jcoins: [null, [Validators.min(1), Validators.pattern('^(0|[1-9][0-9]*)$')]],
                            pointsConversionFactorOut: [{ value: null, disabled: true }, []],
                            // crea: [null, []],
                            // cau: [null, []],
                            // asbea: [null, []],
                            documentCode: [null, []],
                            documentType: [null, []],
                            companyName: [null, []],
                            companyCnpj: [null, []],
                            documentArchitect: [null, []],
                            step1: [null, []],
                            disapproved: [null, []],
                            profession: [null, []],
                            companiesInput: [null, []],
                            origin: [null, []],
                            companyInput: [null, []],
                            otherProfession: [null, []],
                            nickname: [null, []],
                            operatingTime: [null, []],
                            // academicEducation: [null, []],
                            maritalStatus: [null, []],
                            facebook: [null, []],
                            instagram: [null, []],
                            regType: [null, []],
                            licensePlate: [null, []],
                            companies: [{ value: null, disabled: true }, []],
                            storeReferralName: [{ value: null, disabled: true }, []],
                            storeReferralOthers: [{ value: null, disabled: true }, []],
                        });
                        return [4 /*yield*/, this.storeService
                                .listStores()
                                .toPromise()
                                .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    this.stores = response;
                                    return [2 /*return*/];
                                });
                            }); }, function (error) {
                                console.log(error);
                                _this.snackBar.open(error.error.message);
                            })];
                    case 1:
                        _a.sent();
                        this.storeOptions = this.formGroup.controls.store.valueChanges.pipe(startWith(''), map(function (value) {
                            return _this.stores.filter(function (store) {
                                var newValue = typeof value === 'object' ? value.nomeFantasia : value;
                                return (store.nomeFantasia.toLowerCase().includes(newValue.toLowerCase()) ||
                                    store.razaoSocial.toLowerCase().includes(newValue.toLowerCase()) ||
                                    store.cnpj.toLowerCase().includes(newValue.toLowerCase()));
                            });
                        }));
                        if (!(this.typePreRegistration === 'architects')) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.companiesService
                                .list()
                                .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    this.companies = response.companies;
                                    return [2 /*return*/];
                                });
                            }); }, function (error) {
                                console.log(error);
                                _this.snackBar.open(error.error.message);
                            })];
                    case 2:
                        _a.sent();
                        this.filteredCompanies = this.companyControl.valueChanges.pipe(startWith(''), map(function (company) { return (company ? _this._filterCompanies(company) : _this.companies.slice(0, 20)); }));
                        return [3 /*break*/, 4];
                    case 3:
                        this.formGroup.get('nickname').setValidators(null);
                        this.formGroup.get('nickname').updateValueAndValidity();
                        this.formGroup.get('nickname').setErrors(null);
                        this.formGroup.get('documentType').setValidators(null);
                        this.formGroup.get('documentType').updateValueAndValidity();
                        this.formGroup.get('documentType').setErrors(null);
                        this.formGroup.get('documentCode').setValidators(null);
                        this.formGroup.get('documentCode').updateValueAndValidity();
                        this.formGroup.get('documentCode').setErrors(null);
                        // this.formGroup.get('academicEducation').setValidators(null)
                        // this.formGroup.get('academicEducation').updateValueAndValidity()
                        // this.formGroup.get('academicEducation').setErrors(null)
                        this.formGroup.get('maritalStatus').setValidators(null);
                        this.formGroup.get('maritalStatus').updateValueAndValidity();
                        this.formGroup.get('maritalStatus').setErrors(null);
                        this.formGroup.get('operatingTime').setValidators(null);
                        this.formGroup.get('operatingTime').updateValueAndValidity();
                        this.formGroup.get('operatingTime').setErrors(null);
                        this.formGroup.get('emailAddress').setValidators(null);
                        this.formGroup.get('emailAddress').updateValueAndValidity();
                        this.formGroup.get('emailAddress').setErrors(null);
                        this.formGroup.get('companyName').setValidators(null);
                        this.formGroup.get('companyName').updateValueAndValidity();
                        this.formGroup.get('companyName').setErrors(null);
                        this.formGroup.get('birthDate').setValidators(null);
                        this.formGroup.get('birthDate').updateValueAndValidity();
                        this.formGroup.get('birthDate').setErrors(null);
                        this.formGroup.get('gender').setValidators(null);
                        this.formGroup.get('gender').updateValueAndValidity();
                        this.formGroup.get('gender').setErrors(null);
                        this.formGroup.get('profession').setValidators(null);
                        this.formGroup.get('profession').updateValueAndValidity();
                        this.formGroup.get('profession').setErrors(null);
                        this.formGroup.get('profession').setValidators(null);
                        this.formGroup.get('profession').updateValueAndValidity();
                        this.formGroup.get('profession').setErrors(null);
                        _a.label = 4;
                    case 4:
                        if (!this.preRegistrationID) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.loadInfos().catch(function (err) { return console.log(err); })];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    PreRegistrationFormComponent.prototype._filterCompanies = function (company) {
        var _this = this;
        var remove = String(company);
        if (typeof remove !== 'string') {
            return;
        }
        return this.companies
            .filter(function (c) { return !_this.selectedCompanies.includes(c); })
            .filter(function (c) { return (String(c.cnpj) + " - " + String(c.companyName)).toLowerCase().includes(remove.toLowerCase()); })
            .slice(0, 20);
    };
    PreRegistrationFormComponent.prototype.selected = function (event) {
        if (this.selectedCompanies.length < 1) {
            this.selectedCompanies.push(event.option.value);
            this.companyControl.setValue(null);
            if (this.selectedCompanies.length) {
                this.formGroup.patchValue({
                    companyName: event.option.value.companyName,
                });
                this.companyInput.nativeElement.value = '';
                this.companyControl.setValue(null);
            }
        }
    };
    PreRegistrationFormComponent.prototype.remove = function (value) {
        if (value.companyName) {
            var index = this.selectedCompanies.indexOf(value);
            if (index >= 0) {
                this.selectedCompanies.splice(index, 1);
            }
        }
    };
    PreRegistrationFormComponent.prototype.loadInfos = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, responseArchitect, architect, filterCpnj;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.typePreRegistration !== 'architects')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.preRegistrationService.findOne(this.preRegistrationID)];
                    case 1:
                        response = _a.sent();
                        _a.label = 2;
                    case 2:
                        if (!(this.typePreRegistration === 'architects')) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.preRegistrationService.findOneArchitect(this.preRegistrationID)];
                    case 3:
                        architect = _a.sent();
                        response = architect.preRegistration;
                        responseArchitect = architect;
                        this.documentUrl = responseArchitect.documentUrl;
                        this.approveDoc = responseArchitect.documentName;
                        this.formGroup.patchValue({
                            disapproved: responseArchitect.disapproved,
                            step1: responseArchitect.approvedBy ? 'true' : responseArchitect.disapprovedBy ? 'false' : null,
                            // crea: responseArchitect.crea,
                            // cau: responseArchitect.cau,
                            // asbea: responseArchitect.asbea,
                            documentCode: responseArchitect.documentCode,
                            documentType: responseArchitect.documentType,
                            companyName: responseArchitect.companyName,
                            // companyCnpj: responseArchitect.companyCnpj,
                            profession: responseArchitect.profession,
                            otherProfession: responseArchitect.otherProfession,
                            nickname: responseArchitect.nickname,
                            operatingTime: responseArchitect.operatingTime,
                            // academicEducation: responseArchitect.academicEducation,
                            maritalStatus: responseArchitect.maritalStatus,
                            facebook: responseArchitect.facebook,
                            instagram: responseArchitect.instagram,
                            regType: responseArchitect.regType,
                            licensePlate: responseArchitect.licensePlate,
                            companies: responseArchitect.companies,
                            storeReferralName: responseArchitect.storeReferralArchitect ?
                                responseArchitect.storeReferralArchitect.nomeFantasia :
                                '',
                            storeReferralOthers: responseArchitect.storeReferralOthers,
                        });
                        this.storeReferralOthers = !!responseArchitect.storeReferralOthers;
                        this.otherProfession = responseArchitect.profession === 'OTHERS';
                        if (responseArchitect.disapprovedBy) {
                            this.disapprovedDocument = !!responseArchitect.disapproved;
                        }
                        if (responseArchitect.companyCnpj) {
                            filterCpnj = this.companies.filter(function (item) {
                                return responseArchitect.companyCnpj === item.cnpj;
                            });
                            if (filterCpnj.length) {
                                filterCpnj.map(function (item) {
                                    _this.selectedCompanies.push(item);
                                    _this.notCpnj = false;
                                });
                            }
                            else {
                                this.formGroup.patchValue({
                                    companiesInput: responseArchitect.companyCnpj,
                                });
                                this.notCpnj = true;
                            }
                        }
                        _a.label = 4;
                    case 4:
                        this.documentBehind = response.documentBehind;
                        this.documentFront = response.documentFront;
                        this.formGroup.patchValue({
                            cpf: response.cpf,
                            firstName: response.firstName,
                            lastName: response.lastName,
                            gender: response.gender,
                            birthDate: response.birthDate ? response.birthDate.substring(0, 10) : null,
                            emailAddress: response.emailAddress,
                            phoneNumber: response.phoneNumber,
                            isMembership: response.isMembership,
                            planMembership: response.planMembership,
                            firstPayment: response.firstPayment ? response.firstPayment.substring(0, 10) : null,
                            pointsConversionFactorOut: response.balance,
                            jcoins: response.balance,
                            documentCode: responseArchitect ? responseArchitect.documentCode : null,
                            documentType: responseArchitect ? responseArchitect.documentType : null,
                            origin: response.origin,
                        });
                        if (response.balance > 0) {
                            this.formGroup.get('jcoins').disable();
                            this.formGroup.get('store').disable();
                        }
                        this.stores.forEach(function (store) {
                            if (store.id === response.storeId) {
                                _this.formGroup.patchValue({
                                    store: store,
                                });
                            }
                        });
                        this.changeMembershipEnabled({ value: response.isMembership });
                        this.changeTypePlan({ value: response.planMembership });
                        this.croppedDocumentFrontImage = response.imgDocumentFront;
                        this.croppedDocumentBehindImage = response.imgDocumentBehind;
                        if (this.typePreRegistration === 'architects') {
                            this.formGroup.get('cpf').disable();
                            this.cpf = response.cpf;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    PreRegistrationFormComponent.prototype.changeTypePlan = function (event) {
        if (event.value === 'recurrent') {
            this.firstPaymentEnabled = true;
            this.formGroup.get('firstPayment').setValidators([Validators.required]);
            this.formGroup.get('firstPayment').updateValueAndValidity();
        }
        else {
            this.firstPaymentEnabled = false;
            this.formGroup.get('firstPayment').setValidators(null);
            this.formGroup.get('firstPayment').setErrors(null);
        }
    };
    PreRegistrationFormComponent.prototype.changeMembershipEnabled = function (event) {
        if (event.value === true) {
            this.planSelectEnabled = true;
            this.formGroup.get('planMembership').setValidators([Validators.required]);
            this.formGroup.get('planMembership').updateValueAndValidity();
        }
        else {
            this.firstPaymentEnabled = false;
            this.planSelectEnabled = false;
            this.formGroup.get('planMembership').setValidators(null);
            this.formGroup.get('planMembership').setErrors(null);
        }
    };
    PreRegistrationFormComponent.prototype.selectFileDocumentFront = function (event) {
        var _this = this;
        var originalDocumentFrontImageUrl = event.target.files[0];
        var reader = new FileReader();
        reader.onloadend = function (e) {
            _this.originalDocumentFrontImage = reader.result;
        };
        try {
            reader.readAsDataURL(originalDocumentFrontImageUrl);
        }
        catch (e) {
            this.snackBar.open(e.message);
        }
        this.croppedDocumentFrontImage = null;
        this._dialog
            .open(CropperDialogComponent, {
            data: { img: event, config: { width: 38 * 16, height: 10 * 16 } },
            width: 650,
            disableClose: true,
        })
            .afterClosed.toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!result) return [3 /*break*/, 2];
                        this.croppedDocumentFrontImage = result.img.dataURL;
                        this.documentFrontImagePosition = result.config;
                        _a = this;
                        return [4 /*yield*/, base64ToBlob(this.croppedDocumentFrontImage)];
                    case 1:
                        _a.documentFrontImageData = _b.sent();
                        this.frontImageInvalid = !this.croppedDocumentFrontImage;
                        this.documentFront = this.croppedDocumentFrontImage;
                        this._cd.markForCheck();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); })
            .catch(function (error) {
            _this.snackBar.open(error.message);
        });
    };
    PreRegistrationFormComponent.prototype.editDocumentFrontImage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var img, _a, result, _b, error_1;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 7, , 8]);
                        if (!this.originalDocumentFrontImage) return [3 /*break*/, 1];
                        _a = this.originalDocumentFrontImage;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.getBase64ImageFromUrl(this.croppedDocumentFrontImage)];
                    case 2:
                        _a = _c.sent();
                        _c.label = 3;
                    case 3:
                        img = _a;
                        return [4 /*yield*/, this._dialog
                                .open(CropperDialogComponent, {
                                data: {
                                    img: img,
                                    config: tslib_1.__assign({}, this.documentFrontImagePosition, { width: 38 * 16, height: 10 * 16 }),
                                },
                                width: 650,
                                disableClose: true,
                            })
                                .afterClosed.toPromise()];
                    case 4:
                        result = _c.sent();
                        if (!result) return [3 /*break*/, 6];
                        this.croppedDocumentFrontImage = result.img.dataURL;
                        this.documentFrontImagePosition = result.config;
                        _b = this;
                        return [4 /*yield*/, base64ToBlob(this.croppedDocumentFrontImage)];
                    case 5:
                        _b.documentFrontImageData = _c.sent();
                        this.frontImageInvalid = !this.croppedDocumentFrontImage;
                        this._cd.markForCheck();
                        _c.label = 6;
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        error_1 = _c.sent();
                        this.snackBar.open(error_1.message);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    PreRegistrationFormComponent.prototype.selectFileDocumentBehind = function (event) {
        var _this = this;
        var originalDocumentBehindImageUrl = event.target.files[0];
        var reader = new FileReader();
        reader.onloadend = function (e) {
            _this.originalDocumentBehindImage = reader.result;
        };
        try {
            reader.readAsDataURL(originalDocumentBehindImageUrl);
        }
        catch (e) {
            this.snackBar.open(e.message);
        }
        this.croppedDocumentBehindImage = null;
        this._dialog
            .open(CropperDialogComponent, {
            data: { img: event, config: { width: 38 * 16, height: 10 * 16 } },
            width: 650,
            disableClose: true,
        })
            .afterClosed.toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!result) return [3 /*break*/, 2];
                        this.croppedDocumentBehindImage = result.img.dataURL;
                        this.documentBehindImagePosition = result.config;
                        _a = this;
                        return [4 /*yield*/, base64ToBlob(this.croppedDocumentBehindImage)];
                    case 1:
                        _a.documentBehindImageData = _b.sent();
                        this.behindImageInvalid = !this.croppedDocumentBehindImage;
                        this.documentBehind = this.croppedDocumentBehindImage;
                        this._cd.markForCheck();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); })
            .catch(function (error) {
            _this.snackBar.open(error.message);
        });
    };
    PreRegistrationFormComponent.prototype.editDocumentBehindImage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var img, _a, result, _b, error_2;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 7, , 8]);
                        if (!this.originalDocumentBehindImage) return [3 /*break*/, 1];
                        _a = this.originalDocumentBehindImage;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.getBase64ImageFromUrl(this.croppedDocumentBehindImage)];
                    case 2:
                        _a = _c.sent();
                        _c.label = 3;
                    case 3:
                        img = _a;
                        return [4 /*yield*/, this._dialog
                                .open(CropperDialogComponent, {
                                data: {
                                    img: img,
                                    config: tslib_1.__assign({}, this.documentBehindImagePosition, { width: 38 * 16, height: 10 * 16 }),
                                },
                                width: 650,
                                disableClose: true,
                            })
                                .afterClosed.toPromise()];
                    case 4:
                        result = _c.sent();
                        if (!result) return [3 /*break*/, 6];
                        this.croppedDocumentBehindImage = result.img.dataURL;
                        this.documentBehindImagePosition = result.config;
                        _b = this;
                        return [4 /*yield*/, base64ToBlob(this.croppedDocumentBehindImage)];
                    case 5:
                        _b.documentBehindImageData = _c.sent();
                        this.behindImageInvalid = !this.croppedDocumentBehindImage;
                        this._cd.markForCheck();
                        _c.label = 6;
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        error_2 = _c.sent();
                        this.snackBar.open(error_2.message);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    PreRegistrationFormComponent.prototype.getBase64ImageFromUrl = function (imageUrl) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, blob;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(imageUrl, {
                            method: 'GET',
                            mode: 'cors',
                            cache: 'no-cache',
                            keepalive: false,
                            referrer: 'origin-when-cross-origin',
                        })];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, res.blob()];
                    case 2:
                        blob = _a.sent();
                        return [2 /*return*/, new Promise(function (resolve, reject) {
                                var reader = new FileReader();
                                reader.addEventListener('load', function () {
                                    resolve(reader.result);
                                }, false);
                                reader.readAsDataURL(blob);
                            })];
                }
            });
        });
    };
    PreRegistrationFormComponent.prototype.removeImage = function (input) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (input === 'documentFront') {
                    this.readUrlDocumentFront = '';
                    this.originalDocumentFrontImage = undefined;
                    this.croppedDocumentFrontImage = undefined;
                    this.documentFrontImagePosition = undefined;
                    this.documentFrontImageData = undefined;
                    this.frontImageInvalid = true;
                }
                if (input === 'documentBehind') {
                    this.readUrlDocumentBehind = '';
                    this.originalDocumentBehindImage = undefined;
                    this.croppedDocumentBehindImage = undefined;
                    this.documentBehindImagePosition = undefined;
                    this.documentBehindImageData = undefined;
                    this.behindImageInvalid = true;
                }
                return [2 /*return*/];
            });
        });
    };
    PreRegistrationFormComponent.prototype.displayStore = function (store) {
        return store ? "" + store.nomeFantasia : '';
    };
    PreRegistrationFormComponent.prototype.calcValue = function (event) {
        var _this = this;
        this.stores.forEach(function (store) {
            if (store.id === event.option.value.id) {
                _this.store.push(store);
                _this.formGroup.patchValue({
                    store: _this.formGroup.value.store,
                });
            }
        });
        this.jcoinsInvalid = !this.formGroup.value.store;
    };
    PreRegistrationFormComponent.prototype.calcValuePoints = function (event) {
        this.formGroup.patchValue({
            pointsConversionFactorOut: this.formGroup.value.jcoins,
        });
        if (!this.formGroup.value.jcoins)
            this.jcoinsInvalid = false;
    };
    PreRegistrationFormComponent.prototype.goBack = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.typePreRegistration === 'architects')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.router.navigate(['./dashboard/pre-registration-architects/architects'])];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 2:
                        if (!(this.typePreRegistration === 'CMS')) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.router.navigate(['./dashboard/pre-registration/CMS'])];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 4: return [4 /*yield*/, this.router.navigate(['./dashboard/pre-registration'])];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    PreRegistrationFormComponent.prototype.selectFile = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var reader_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (event.target.files && event.target.files[0]) {
                    reader_1 = new FileReader();
                    reader_1.readAsDataURL(event.target.files[0]); // read file as data url
                    reader_1.onload = function () {
                        _this.documentUrl = reader_1.result;
                    };
                    this.uploadData = new FormData();
                    this.uploadData.append('file', event.target.files[0], event.target.files[0].name);
                    this.approveDoc = event.target.files[0].name;
                }
                else {
                    this.removeDocument();
                }
                return [2 /*return*/];
            });
        });
    };
    PreRegistrationFormComponent.prototype.removeDocument = function () {
        this.documentUrl = null;
        this.uploadData = null;
        this.approveDoc = undefined;
        this.fileInputBanner.nativeElement.value = '';
    };
    PreRegistrationFormComponent.prototype.download = function (event) {
        var _this = this;
        event.preventDefault();
        // eslint-disable-next-line no-new
        new JsFileDownloader({
            url: this.documentUrl,
            nameCallback: function () {
                // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                return _this.approveDoc;
            },
        });
    };
    PreRegistrationFormComponent.prototype.changeApproved = function (event) {
        if (event) {
            this.disapprovedDocument = false;
            this.approvedDocument = true;
            this.formGroup.get('disapproved').setValidators(null);
            this.formGroup.get('disapproved').updateValueAndValidity();
            this.formGroup.get('disapproved').setErrors(null);
            this.formGroup.value.disapproved = null;
        }
        else {
            this.disapprovedDocument = true;
            this.approvedDocument = false;
            this.formGroup.get('disapproved').setValidators([Validators.required, Validators.min(1)]);
            this.formGroup.get('disapproved').updateValueAndValidity();
            this.formGroup.value.approved = null;
        }
    };
    PreRegistrationFormComponent.prototype.isChecked = function (disapprovedDocument) {
        if (this.preRegistrationID) {
            if (!disapprovedDocument) {
                return 'checked';
            }
            else if (disapprovedDocument) {
                return 'checked';
            }
        }
    };
    PreRegistrationFormComponent.prototype.valueInput = function (e) {
        if (e.target.value) {
            this.formGroup.value.companiesInput = e.target.value.replace(/[^\w]/g, '');
            this.companyCnpj = e.target.value.replace(/[^\w]/g, '');
            // this.notCpnj = true
        }
        // else {
        //   this.notCpnj = false
        // }
    };
    PreRegistrationFormComponent.prototype.others = function (e) {
        if (e === 'OTHERS') {
            this.otherProfession = true;
        }
        else {
            this.otherProfession = false;
        }
    };
    PreRegistrationFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, response, data, data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.formGroup.value.jcoins) {
                            this.jcoinsInvalid = !this.formGroup.value.store;
                        }
                        if (!this.formGroup.valid || this.jcoinsInvalid) {
                            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                            return [2 /*return*/, false];
                        }
                        if (!(this.croppedDocumentFrontImage && this.documentFrontImageData)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.authService.getS3Url('documentFront')];
                    case 1:
                        response = _a.sent();
                        this.readUrlDocumentFront = response.readUrl;
                        return [4 /*yield*/, this.authService.uploadToS3(response.uploadUrl, this.documentFrontImageData)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        if (!(this.croppedDocumentBehindImage && this.documentBehindImageData)) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.authService.getS3Url('documentBehind')];
                    case 4:
                        response = _a.sent();
                        this.readUrlDocumentBehind = response.readUrl;
                        return [4 /*yield*/, this.authService.uploadToS3(response.uploadUrl, this.documentBehindImageData)];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6:
                        if (!(this.typePreRegistration !== 'architects')) return [3 /*break*/, 10];
                        data = {
                            cpf: this.formGroup.value.cpf,
                            firstName: this.formGroup.value.firstName,
                            lastName: this.formGroup.value.lastName,
                            gender: this.formGroup.value.gender,
                            emailAddress: this.formGroup.value.emailAddress,
                            phoneNumber: this.formGroup.value.phoneNumber,
                            membershipEnabled: this.formGroup.value.membershipEnabled,
                            imgDocumentFront: this.readUrlDocumentFront,
                            imgDocumentBehind: this.readUrlDocumentBehind,
                            birthDate: this.formGroup.value.birthDate ? moment(this.formGroup.value.birthDate).format('YYYY-MM-DD') : null,
                            isMembership: this.formGroup.value.isMembership,
                            planMembership: this.formGroup.value.planMembership,
                            firstPayment: this.formGroup.value.firstPayment,
                            documentFront: this.readUrlDocumentFront ? this.documentFront : undefined,
                            documentBehind: this.readUrlDocumentBehind ? this.documentBehind : undefined,
                            balance: this.formGroup.value.jcoins,
                            storeId: this.formGroup.value.store ? this.formGroup.value.store.id : undefined,
                            origin: this.formGroup.value.origin ? this.formGroup.value.origin : undefined,
                            typeRegistration: this.typePreRegistration ? this.typePreRegistration.toUpperCase() : null,
                        };
                        if (!this.preRegistrationID) return [3 /*break*/, 8];
                        return [4 /*yield*/, this.preRegistrationService.update(this.preRegistrationID, data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!response.isUpdated) return [3 /*break*/, 5];
                                            if (!(this.typePreRegistration === 'CMS')) return [3 /*break*/, 2];
                                            return [4 /*yield*/, this.router.navigate(['./dashboard/pre-registration/CMS'])];
                                        case 1:
                                            _a.sent();
                                            return [3 /*break*/, 4];
                                        case 2:
                                            if (!(this.typePreRegistration === 'others')) return [3 /*break*/, 4];
                                            return [4 /*yield*/, this.router.navigate(['./dashboard/pre-registration'])];
                                        case 3:
                                            _a.sent();
                                            _a.label = 4;
                                        case 4:
                                            this.snackBar.open('Pré-Cadastro atualizada com sucesso.');
                                            _a.label = 5;
                                        case 5: return [2 /*return*/];
                                    }
                                });
                            }); }, function (error) {
                                _this.snackBar.open(error.error.message);
                            })];
                    case 7:
                        _a.sent();
                        return [3 /*break*/, 10];
                    case 8: return [4 /*yield*/, this.preRegistrationService.create(data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!response.isCreated) return [3 /*break*/, 5];
                                        if (!(this.typePreRegistration === 'CMS')) return [3 /*break*/, 2];
                                        return [4 /*yield*/, this.router.navigate(['./dashboard/pre-registration/CMS'])];
                                    case 1:
                                        _a.sent();
                                        return [3 /*break*/, 4];
                                    case 2:
                                        if (!(this.typePreRegistration === 'others')) return [3 /*break*/, 4];
                                        return [4 /*yield*/, this.router.navigate(['./dashboard/pre-registration'])];
                                    case 3:
                                        _a.sent();
                                        _a.label = 4;
                                    case 4:
                                        this.snackBar.open('Pré-Cadastro criado com sucesso.');
                                        _a.label = 5;
                                    case 5: return [2 /*return*/];
                                }
                            });
                        }); }, function (error) {
                            _this.snackBar.open(error.error.message);
                        })];
                    case 9:
                        _a.sent();
                        _a.label = 10;
                    case 10:
                        if (!(this.typePreRegistration === 'architects')) return [3 /*break*/, 14];
                        if (this.approveDoc) {
                            if (this.approveDoc.indexOf('.pdf') <= 0 && this.approveDoc.indexOf('.png') <= 0 && this.approveDoc.indexOf('.jpg') <= 0) {
                                this.snackBar.open('Documento Arquiteto precisa ser PDF, JPG ou PNG .');
                                return [2 /*return*/, false];
                            }
                        }
                        data = {
                            cpf: this.formGroup.value.cpf || this.cpf,
                            firstName: this.formGroup.value.firstName,
                            lastName: this.formGroup.value.lastName,
                            gender: this.formGroup.value.gender,
                            emailAddress: this.formGroup.value.emailAddress,
                            phoneNumber: this.formGroup.value.phoneNumber,
                            membershipEnabled: this.formGroup.value.membershipEnabled,
                            imgDocumentFront: this.readUrlDocumentFront,
                            imgDocumentBehind: this.readUrlDocumentBehind,
                            birthDate: this.formGroup.value.birthDate ? moment(this.formGroup.value.birthDate).format('YYYY-MM-DD') : null,
                            isMembership: this.formGroup.value.isMembership,
                            planMembership: this.formGroup.value.planMembership,
                            firstPayment: this.formGroup.value.firstPayment,
                            documentFront: this.readUrlDocumentFront ? this.documentFront : undefined,
                            documentBehind: this.readUrlDocumentBehind ? this.documentBehind : undefined,
                            balance: this.formGroup.value.jcoins,
                            storeId: this.formGroup.value.store ? this.formGroup.value.store.id : undefined,
                            document: this.documentUrl ? this.documentUrl : undefined,
                            documentName: this.approveDoc,
                            approved: this.approvedDocument,
                            disapproved: this.disapprovedDocument ? this.formGroup.value.disapproved : undefined,
                            companyCnpj: this.selectedCompanies.length ?
                                this.selectedCompanies[0].cnpj :
                                this.companyCnpj,
                            companyName: this.formGroup.value.companyName,
                            // asbea: this.formGroup.value.asbea,
                            // cau: this.formGroup.value.cau,
                            // crea: this.formGroup.value.crea,
                            documentType: this.formGroup.value.documentType || undefined,
                            documentCode: this.formGroup.value.documentCode,
                            profession: this.formGroup.value.profession,
                            isStep1Done: this.preRegistrationID ? this.formGroup.value.step1 === 'true' : undefined,
                            origin: this.formGroup.value.origin ? this.formGroup.value.origin : undefined,
                            nickname: this.formGroup.value.nickname,
                            otherProfession: this.formGroup.value.otherProfession,
                            operatingTime: this.formGroup.value.operatingTime,
                            // academicEducation: this.formGroup.value.academicEducation,
                            maritalStatus: this.formGroup.value.maritalStatus,
                            facebook: this.formGroup.value.facebook,
                            instagram: this.formGroup.value.instagram,
                            regType: this.formGroup.value.regType,
                            licensePlate: this.formGroup.value.licensePlate,
                            storeReferralName: this.formGroup.value.storeReferralName,
                        };
                        if (!this.preRegistrationID) return [3 /*break*/, 12];
                        return [4 /*yield*/, this.preRegistrationService.updateArchitect(this.preRegistrationID, data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!response.isUpdated) return [3 /*break*/, 2];
                                            return [4 /*yield*/, this.router.navigate(['./dashboard/pre-registration-architects/architects'])];
                                        case 1:
                                            _a.sent();
                                            this.snackBar.open('Pré-Cadastro atualizada com sucesso.');
                                            _a.label = 2;
                                        case 2: return [2 /*return*/];
                                    }
                                });
                            }); }, function (error) {
                                _this.snackBar.open(error.error.message);
                            })];
                    case 11:
                        _a.sent();
                        return [3 /*break*/, 14];
                    case 12: return [4 /*yield*/, this.preRegistrationService.createArchitect(data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!response.isCreated) return [3 /*break*/, 2];
                                        return [4 /*yield*/, this.router.navigate(['./dashboard/pre-registration-architects/architects'])];
                                    case 1:
                                        _a.sent();
                                        this.snackBar.open('Pré-Cadastro criado com sucesso.');
                                        _a.label = 2;
                                    case 2: return [2 /*return*/];
                                }
                            });
                        }); }, function (error) {
                            _this.snackBar.open(error.error.message);
                        })];
                    case 13:
                        _a.sent();
                        _a.label = 14;
                    case 14: return [2 /*return*/];
                }
            });
        });
    };
    return PreRegistrationFormComponent;
}());
export { PreRegistrationFormComponent };
