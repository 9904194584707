import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'

import { ErrorsService } from 'src/app/core/services/errors.service'
import { ZipCodeService } from 'src/app/core/services/zip-code.service'

// import { PasswordValidator } from 'src/app/validators/password.validator'
import { StoreService } from 'src/app/services/store.service'
import { Router, ActivatedRoute } from '@angular/router'
import { AuthService } from 'src/app/services/auth.service'
import { CustomerAttendantService } from '../../../../services/customer-attendant.service'
import { MatSnackBar } from '@angular/material'

import { LyDialog } from '@alyle/ui/dialog'
import { base64ToBlob } from 'base64-blob'
import { ImgCropperEvent } from '@alyle/ui/image-cropper'
import { NgxImageCompressService } from 'ngx-image-compress'
import { CropperDialogComponent } from '../../../../components/cropper-dialog/cropper-dialog.component'
import { Location } from '@angular/common'
import JsFileDownloader from 'js-file-downloader'
import { CompaniesService } from '../../../../services/companies.service'
import { Observable } from 'rxjs'
import { map, startWith } from 'rxjs/operators'
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete'
@Component({
  selector: 'client-form-update',
  templateUrl: './client-form-update.component.html',
  styleUrls: ['./client-form-update.component.scss'],
})
export class ClientFormUpdateComponent implements OnInit {
  public formGroup: FormGroup
  // eslint-disable-next-line @typescript-eslint/ban-types
  public states: Object[] = []
  // eslint-disable-next-line @typescript-eslint/ban-types
  public plans: Object[] = []
  public customerID: any
  public customer: any
  public firstPaymentEnabled = false
  public planSelectEnabled = false
  public params = { formGroupValue: [] }

  private originalDocumentFrontImage: any
  public croppedDocumentFrontImage: any
  private documentFrontImagePosition: any
  private documentFrontImageData: any
  public readUrlDocumentFront: any

  private originalDocumentBehindImage: any
  public croppedDocumentBehindImage: any
  private documentBehindImagePosition: any
  private documentBehindImageData: any
  public readUrlDocumentBehind: any

  public frontImageInvalid = false
  public behindImageInvalid = false

  public kinds: any[] = []
  public attendants: any[] = []
  public createdOnLlLoyalty: string
  public hasRoadpassWallet: string
  public zoopBuyerId: string
  public salesForceId: string

  public documentUrl: any
  public uploadData: FormData
  public approveDoc: string
  public disapprovedDocument = false
  public approvedDocument: boolean

  public architect: any
  public notCpnj = false
  public companies: any[] = []
  public companyControl = new FormControl()
  filteredCompanies: Observable<string[]>
  public stores: any[] = []
  public storeOptions: Observable<any[]>
  public selectedCompanies: any[] = []
  public companyCnpj: string

  @ViewChild('fileInputBanner', { static: false }) fileInputBanner: ElementRef
  @ViewChild('companyInput', { static: false }) companyInput: ElementRef<HTMLInputElement>

  constructor (
    public errorsService: ErrorsService,
    private readonly zipCodeService: ZipCodeService,
    private readonly formBuilder: FormBuilder,
    public storeService: StoreService,
    public authService: AuthService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly snackBar: MatSnackBar,
    private readonly _dialog: LyDialog,
    private readonly _cd: ChangeDetectorRef,
    private readonly imageCompress: NgxImageCompressService,
    public customerAttendantService: CustomerAttendantService,
    public location: Location,
    private readonly companiesService: CompaniesService,
  ) {
    this.initForm().catch((error) => console.log(error))
  }

  ngOnInit () {
    this.route.queryParams.subscribe((params) => {
      this.params.formGroupValue = params.formGroupValue
    })
    // empty
  }

  async initForm () {
    this.customerID = this.route.snapshot.paramMap.get('customerID')
    this.architect = this.route.snapshot.paramMap.get('architect')
    this.formGroup = this.formBuilder.group({
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      nickname: [null, []],
      gender: [null, [Validators.required]],
      blockTransaction: [null, []],
      blockTransactionJcoins: [null, []],
      birthDate: [null, []],
      emailAddress: [null, [Validators.required, Validators.email]],
      phoneNumber: [null, [Validators.required]],
      membershipEnabled: [null, []],
      plan: [null, []],
      firstPayment: [null, []],
      kind: [null, []],
      attendant: [null, []],
      createdOnLlLoyalty: [null, []],
      // crea: [null, []],
      // cau: [null, []],
      // asbea: [null, []],
      documentCode: [null, []],
      documentType: [null, []],
      companyName: [null, []],
      companyCnpj: [null, []],
      documentArchitect: [null, []],
      profession: [null, []],
      origin: [null, []],
      operatingTime: [null, []],
      // academicEducation: [null, []],
      maritalStatus: [null, []],
      facebook: [null, []],
      instagram: [null, []],
      companyInput: [null, []],
      licensePlate: [null, []],
      companies: [{ value: null, disabled: true }, []],
      storeReferralName: [{ value: null, disabled: true }, []],
    })
    await this.storeService
      .listStores()
      .toPromise()
      .then(
        async response => {
          this.stores = response
        },
        error => {
          console.log(error)
          this.snackBar.open(error.error.message)
        },
      )

    if (this.architect) {
      await this.companiesService
        .list()
        .then(
          async response => {
            this.companies = response.companies
          },
          error => {
            console.log(error)
            this.snackBar.open(error.error.message)
          },
        )

      this.filteredCompanies = this.companyControl.valueChanges.pipe(
        startWith(''),
        map((company: any) => (
          company ? this._filterCompanies(company) : this.companies.slice(0, 20))),
      )
      await this.customerArchitect().catch(err => console.log(err))
    } else {
      await this.loadCustomerInfo().catch(err => console.log(err))
    }
  }

  private _filterCompanies (company: string): string[] {
    const remove = String(company)

    if (typeof remove !== 'string') {
      return
    }

    return this.companies
      .filter(c => !this.selectedCompanies.includes(c))
      .filter(c => `${String(c.cnpj)} - ${String(c.companyName)}`.toLowerCase().includes(remove.toLowerCase()))
      .slice(0, 20)
  }

  selected (event: MatAutocompleteSelectedEvent): void {
    if (this.selectedCompanies.length < 1) {
      this.selectedCompanies.push(event.option.value)
      this.companyControl.setValue(null)
      if (this.selectedCompanies.length) {
        this.formGroup.patchValue({
          companyName: event.option.value.companyName,
        })
        this.companyInput.nativeElement.value = ''
        this.companyControl.setValue(null)
      }
    }
  }

  remove (value: any): void {
    if (value.companyName) {
      this.formGroup.patchValue({
        companyName: null,
      })
      const index = this.selectedCompanies.indexOf(value)
      if (index >= 0) {
        this.selectedCompanies.splice(index, 1)
      }
    }
  }

  changeTypePlan (event) {
    if (event.value === 'recurrent' && this.customer.plan !== 'recurrent-active') {
      this.firstPaymentEnabled = true
      this.formGroup.get('firstPayment').setValidators([Validators.required])
      this.formGroup.get('firstPayment').updateValueAndValidity()
    } else {
      this.firstPaymentEnabled = false
      this.formGroup.get('firstPayment').setValidators(null)
      this.formGroup.get('firstPayment').setErrors(null)
    }
  }

  changeMembershipEnabled (event) {
    if (event.value === true) {
      this.planSelectEnabled = true
      this.formGroup.get('plan').setValidators([Validators.required])
      this.formGroup.get('plan').updateValueAndValidity()
    } else {
      this.firstPaymentEnabled = false
      this.planSelectEnabled = false
      this.formGroup.get('plan').setValidators(null)
      this.formGroup.get('plan').setErrors(null)
      this.formGroup.get('plan').setValue('none')
    }
  }

  selectFileDocumentFront (event: Event) {
    const originalDocumentFrontImageUrl = (event.target as any).files[0]

    const reader: FileReader = new FileReader()
    reader.onloadend = e => {
      this.originalDocumentFrontImage = reader.result
    }
    try {
      reader.readAsDataURL(originalDocumentFrontImageUrl)
    } catch (e) {
      this.snackBar.open(e.message)
    }

    this.croppedDocumentFrontImage = null!
    this._dialog
      .open<CropperDialogComponent, any>(CropperDialogComponent, {
      data: { img: event, config: { width: 38 * 16, height: 10 * 16 } },
      width: 650,
      disableClose: true,
    })
      .afterClosed.toPromise()
      .then(async (result?: { img: ImgCropperEvent, config: any }) => {
        if (result) {
          this.croppedDocumentFrontImage = result.img.dataURL
          this.documentFrontImagePosition = result.config
          this.documentFrontImageData = await base64ToBlob(this.croppedDocumentFrontImage)
          this.frontImageInvalid = !this.croppedDocumentFrontImage
          this._cd.markForCheck()
        }
      })
      .catch(error => {
        this.snackBar.open(error.message)
      })
  }

  async editDocumentFrontImage () {
    try {
      const img = this.originalDocumentFrontImage
        ? this.originalDocumentFrontImage
        : await this.getBase64ImageFromUrl(this.croppedDocumentFrontImage)

      const result: { img: ImgCropperEvent, config: any } | undefined = await this._dialog
        .open(CropperDialogComponent, {
          data: {
            img,
            config: { ...this.documentFrontImagePosition, width: 38 * 16, height: 10 * 16 },
          },
          width: 650,
          disableClose: true,
        })
        .afterClosed.toPromise()
      if (result) {
        this.croppedDocumentFrontImage = result.img.dataURL
        this.documentFrontImagePosition = result.config
        this.documentFrontImageData = await base64ToBlob(this.croppedDocumentFrontImage)
        this.frontImageInvalid = !this.croppedDocumentFrontImage
        this._cd.markForCheck()
      }
    } catch (error) {
      this.snackBar.open(error.message)
    }
  }

  selectFileDocumentBehind (event: Event) {
    const originalDocumentBehindImageUrl = (event.target as any).files[0]

    const reader: FileReader = new FileReader()
    reader.onloadend = e => {
      this.originalDocumentBehindImage = reader.result
    }
    try {
      reader.readAsDataURL(originalDocumentBehindImageUrl)
    } catch (e) {
      this.snackBar.open(e.message)
    }

    this.croppedDocumentBehindImage = null!
    this._dialog
      .open<CropperDialogComponent, any>(CropperDialogComponent, {
      data: { img: event, config: { width: 38 * 16, height: 10 * 16 } },
      width: 650,
      disableClose: true,
    })
      .afterClosed.toPromise()
      .then(async (result?: { img: ImgCropperEvent, config: any }) => {
        if (result) {
          this.croppedDocumentBehindImage = result.img.dataURL
          this.documentBehindImagePosition = result.config
          this.documentBehindImageData = await base64ToBlob(this.croppedDocumentBehindImage)
          this.behindImageInvalid = !this.croppedDocumentBehindImage
          this._cd.markForCheck()
        }
      })
      .catch(error => {
        this.snackBar.open(error.message)
      })
  }

  async editDocumentBehindImage () {
    try {
      const img = this.originalDocumentBehindImage
        ? this.originalDocumentBehindImage
        : await this.getBase64ImageFromUrl(this.croppedDocumentBehindImage)

      const result: { img: ImgCropperEvent, config: any } | undefined = await this._dialog
        .open(CropperDialogComponent, {
          data: {
            img,
            config: { ...this.documentBehindImagePosition, width: 38 * 16, height: 10 * 16 },
          },
          width: 650,
          disableClose: true,
        })
        .afterClosed.toPromise()
      if (result) {
        this.croppedDocumentBehindImage = result.img.dataURL
        this.documentBehindImagePosition = result.config
        this.documentBehindImageData = await base64ToBlob(this.croppedDocumentBehindImage)
        this.behindImageInvalid = !this.croppedDocumentBehindImage
        this._cd.markForCheck()
      }
    } catch (error) {
      this.snackBar.open(error.message)
    }
  }

  async getBase64ImageFromUrl (imageUrl) {
    const res = await fetch(imageUrl, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      keepalive: false,
      referrer: 'origin-when-cross-origin',
    })
    const blob = await res.blob()

    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        function () {
          resolve(reader.result)
        },
        false,
      )
      reader.readAsDataURL(blob)
    })
  }

  async removeImage (input) {
    if (input === 'documentFront') {
      this.readUrlDocumentFront = ''
      this.originalDocumentFrontImage = undefined
      this.croppedDocumentFrontImage = undefined
      this.documentFrontImagePosition = undefined
      this.documentFrontImageData = undefined

      this.frontImageInvalid = true
    }
    if (input === 'documentBehind') {
      this.readUrlDocumentBehind = ''
      this.originalDocumentBehindImage = undefined
      this.croppedDocumentBehindImage = undefined
      this.documentBehindImagePosition = undefined
      this.documentBehindImageData = undefined

      this.behindImageInvalid = true
    }
  }

  goBack () {
    if (this.architect) {
      this.router.navigate(['./dashboard/clients-architect/architect'])
    } else {
      this.router.navigate(['./dashboard/clients'])
    }
  }

  async submit () {
    this.frontImageInvalid = !this.croppedDocumentFrontImage
    this.behindImageInvalid = !this.croppedDocumentBehindImage
    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }
    // if (this.planSelectEnabled && !this.formGroup.value.plan) {
    //   this.formGroup.get('plan').setValue(null)
    //   this.formGroup.get('plan').setValidators(Validators.required)
    //   return false
    // }

    if (this.croppedDocumentFrontImage && this.documentFrontImageData) {
      const response = await this.authService.getS3Url('documentFront')
      this.readUrlDocumentFront = response.readUrl
      await this.authService.uploadToS3(response.uploadUrl, this.documentFrontImageData)
    }

    if (this.croppedDocumentBehindImage && this.documentBehindImageData) {
      const response = await this.authService.getS3Url('documentBehind')
      this.readUrlDocumentBehind = response.readUrl
      await this.authService.uploadToS3(response.uploadUrl, this.documentBehindImageData)
    }

    const data: any = {
      firstName: this.formGroup.value.firstName,
      lastName: this.formGroup.value.lastName,
      nickname: this.formGroup.value.nickname,
      gender: this.formGroup.value.gender,
      blockTransaction: this.formGroup.value.blockTransaction,
      blockTransactionJcoins: this.formGroup.value.blockTransactionJcoins,
      birthDate: this.formGroup.value.birthDate || null,
      emailAddress: this.formGroup.value.emailAddress,
      phoneNumber: this.formGroup.value.phoneNumber,
      membershipEnabled: this.formGroup.value.membershipEnabled,
      imgDocumentFront: this.readUrlDocumentFront,
      imgDocumentBehind: this.readUrlDocumentBehind,
      customerKindId: this.formGroup.value.kind,
      attendantCustomerId: this.formGroup.value.attendant !== '' ? this.formGroup.value.attendant : null,
      // crea: this.formGroup.value.crea,
      // cau: this.formGroup.value.cau,
      // asbea: this.formGroup.value.asbea,
      documentCode: this.formGroup.value.documentCode,
      documentType: this.formGroup.value.documentType,
      companyName: this.formGroup.value.companyName,
      companyCnpj: this.formGroup.value.companyCnpj,
      profession: this.formGroup.value.profession,
      documentUrl: this.documentUrl ? this.documentUrl : undefined,
      documentName: this.approveDoc,
      origin: this.formGroup.value.origin ? this.formGroup.value.origin : undefined,
      operatingTime: this.formGroup.value.operatingTime,
      // academicEducation: this.formGroup.value.academicEducation,
      maritalStatus: this.formGroup.value.maritalStatus,
      facebook: this.formGroup.value.facebook,
      instagram: this.formGroup.value.instagram,
      licensePlate: this.formGroup.value.licensePlate,
      storeReferralName: this.formGroup.value.storeReferralName,
    }

    if (this.formGroup.value.membershipEnabled && !this.architect) {
      data.plan = this.formGroup.value.plan
    }

    if (this.formGroup.value.plan === 'recurrent') {
      data.firstPayment = this.formGroup.value.firstPayment
    }

    if (this.architect) {
      await this.authService.customerUpdateArchitect(this.customerID, data).then(
        async response => {
          this.snackBar.open('Cliente editado com sucesso.')
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.router.navigate(['./dashboard/clients-architect/architect'])
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    } else {
      this.authService.customerUpdate(this.customerID, data).subscribe(
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        async response => {
          this.snackBar.open('Cliente editado com sucesso.')
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          if (this.architect) {
            this.router.navigate(['./dashboard/clients-architect/architect'])
          } else {
            this.router.navigate(['./dashboard/clients'])
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    }
  }

  async loadCustomerInfo () {
    await this.authService.clientKindList().then(
      response => {
        this.kinds = response.results
      },
      error => {
        console.log(error)
        this.snackBar.open(error.error.message)
      },
    )
    await this.authService.customerUserInfo(this.customerID).subscribe(
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      async response => {
        this.customer = response
        this.setInfosCustomer()
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
    this.customerAttendantService.list().then(
      response => {
        this.attendants = response.results
      },
      error => {
        console.log(error)
        this.snackBar.open(error.error.message)
      },
    )
  }

  valueInput (e) {
    if (e.target.value) {
      this.formGroup.value.companiesInput = e.target.value.replace(/[^\w]/g, '')
      this.companyCnpj = e.target.value.replace(/[^\w]/g, '')
      // this.notCpnj = true
    }
    // else {
    //   this.notCpnj = false
    // }
  }

  setInfosCustomer () {
    this.createdOnLlLoyalty = this.customer.createdOnLlLoyalty
    this.hasRoadpassWallet = this.customer.hasRoadpassWallet
    this.zoopBuyerId = this.customer.zoopBuyerId
    this.salesForceId = this.customer.salesForceId
    const blockTransactionJcoins =
      !!(this.customer.blockTransactionJcoins === 1 ||
        this.customer.blockTransactionJcoins === true)

    this.formGroup.patchValue({
      firstName: this.customer.firstName,
      lastName: this.customer.lastName,
      nickname: this.architect ? this.customer.nickname : undefined,
      gender: this.customer.gender,
      blockTransaction: this.customer.blockTransaction === '1',
      blockTransactionJcoins: blockTransactionJcoins,
      birthDate:
        this.customer.birthDate ? this.customer.birthDate.substring(0, 10) : null,
      emailAddress: this.customer.emailAddress,
      phoneNumber: this.customer.phoneNumber,
      membershipEnabled: this.customer.membershipEnabled,
      kind: this.customer.customerKindId,
      attendant: this.customer.attendantCustomerId ? this.customer.attendantCustomerId : '',
      // crea: this.architect ? this.customer.crea : undefined,
      // cau: this.architect ? this.customer.cau : undefined,
      // asbea: this.architect ? this.customer.asbea : undefined,
      documentType: this.architect && this.customer.documentType ?
        this.customer.documentType :
        undefined,
      documentCode: this.architect ? this.customer.documentCode : undefined,
      companyName: this.architect ? this.customer.companyName : undefined,
      // companyCnpj: this.architect ? this.customer.companyCnpj : undefined,
      profession: this.architect ? this.customer.profession : undefined,
      origin: this.customer.origin,
      operatingTime: this.architect ? this.customer.operatingTime : undefined,
      // academicEducation: this.architect ? this.customer.academicEducation : undefined,
      maritalStatus: this.architect ? this.customer.maritalStatus : undefined,
      facebook: this.architect ? this.customer.facebook : undefined,
      instagram: this.architect ? this.customer.instagram : undefined,
      licensePlate: this.architect ? this.customer.licensePlate : undefined,
      companyCnpj: this.selectedCompanies.length ?
        this.selectedCompanies[0].cnpj :
        this.companyCnpj,
      companies: this.architect ? this.customer.companies : undefined,
      storeReferralName: this.architect && this.customer.storeReferralArchitect
        ? this.customer.storeReferralArchitect.storeReferralNomeFantasia
        : undefined,
    })

    if (this.architect) {
      this.documentUrl = this.customer.documentUrl
      this.approveDoc = this.customer.documentName
    }

    this.croppedDocumentFrontImage = this.customer.imgDocumentFront
    this.croppedDocumentBehindImage = this.customer.imgDocumentBehind

    if (this.customer.plan) {
      if (this.customer.plan === 'recurrent-active') {
        this.formGroup.controls.plan.setValue('recurrent')
      } else {
        this.formGroup.controls.plan.setValue(this.customer.plan)
      }
      this.formGroup.controls.firstPayment.setValue(this.customer.firstPayment)
    }

    this.changeMembershipEnabled({ value: this.customer.membershipEnabled })
    this.changeTypePlan({ value: this.customer.plan })

    if (this.customer.companyCnpj) {
      const filterCpnj = this.companies.filter(item =>
        this.customer.companyCnpj === item.cnpj,
      )
      if (filterCpnj.length) {
        filterCpnj.map(item => {
          this.selectedCompanies.push(item)
          this.notCpnj = false
        })
      } else {
        this.formGroup.patchValue({
          companyCnpj: this.customer.companyCnpj,
        })
        this.notCpnj = true
      }
    }
  }

  async selectFile (event: any) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader()

      reader.readAsDataURL(event.target.files[0]) // read file as data url

      reader.onload = () => {
        this.documentUrl = reader.result
      }

      this.uploadData = new FormData()
      this.uploadData.append('file', event.target.files[0], event.target.files[0].name)
      this.approveDoc = event.target.files[0].name
    } else {
      this.removeDocument()
    }
  }

  removeDocument () {
    this.documentUrl = null
    this.uploadData = null
    this.approveDoc = undefined
    this.fileInputBanner.nativeElement.value = ''
  }

  download (event) {
    event.preventDefault()
    // eslint-disable-next-line no-new
    new JsFileDownloader({
      url: this.documentUrl,
      nameCallback: () => {
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        return this.approveDoc
      },
    })
  }

  async customerArchitect () {
    await this.authService.clientKindList().then(
      response => {
        this.kinds = response.results
      },
      error => {
        console.log(error)
        this.snackBar.open(error.error.message)
      },
    )
    this.authService
      .findOneCustomerArchitect(this.customerID)
      .then(
        async response => {
          if (response) {
            this.customer = response
            this.setInfosCustomer()
          }
        },
        error => {
          console.log(error)
          alert(error.error.message)
        },
      )
  }
}
